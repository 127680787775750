import { useTranslation } from 'react-i18next';
import Container from 'components/layout/Container';
import MainWithBackButton from 'components/layout/MainWithBackButton';
import Panel from 'components/layout/Panel';
import Description from 'components/StepDescription/Description';

const TermOfServices = () => {
    const { t } = useTranslation();

    return (
        <Container bgColor="white">
            <Panel>
                <Description
                    subtitle={t('common.legalNotices.account.description')}
                    title={t('common.legalNotices.account.title')}
                />
            </Panel>
            <MainWithBackButton title={t('common.termsOfService.title')}>
                <div
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: t('common.termsOfService.content') }}
                    className="static-page"
                />
            </MainWithBackButton>
        </Container>
    );
};

export default TermOfServices;
