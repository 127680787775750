import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkToken, createForm, getUser } from 'actions';
import LanguageSwitcher from 'components/common/LanguageSwitcher';
import { useAppData } from 'hooks/useAppData';
import useLocalizedHistoryPush from 'hooks/useLocalizedHistoryPush';
import { useMenu } from 'hooks/useMenu';
import useUncompleteFormRedirection from 'hooks/useUncompleteFormRedirection';
import { ROUTES } from 'utils/constants';
import './style.scss';

const Menu = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openMenu } = useMenu();
    const { user } = useSelector((state) => state);
    const { API_URL } = useAppData();
    const { userHasUncompletedForm, formRedirection } = useUncompleteFormRedirection(API_URL);
    const push = useLocalizedHistoryPush();

    useEffect(() => {
        async function fetchToken() {
            const result = await dispatch(checkToken());
            if (result?.success) {
                await dispatch(getUser(API_URL));
            }
        }
        if (!user) {
            fetchToken();
        }
    }, []);

    const restartForm = async () => {
        await dispatch(createForm(API_URL));
        push(ROUTES.step1.part1);
    };

    return (
        <div className="menu">
            <h1 className="menu__title">{t('menu.title', { surname: user?.surname })}</h1>
            {user && <LanguageSwitcher color="black" />}
            <ul className="menu__inner">
                <li className="menu__link">
                    <Link
                        onClick={() => openMenu(false)}
                        to="/account"
                    >
                        <img
                            alt={t('menu.account')}
                            src="/assets/icons/house.svg"
                        />
                        {t('menu.account')}
                    </Link>
                </li>
                {userHasUncompletedForm && (
                    <li className="menu__link">
                        <Link
                            onClick={() => openMenu(false)}
                            to={formRedirection}
                        >
                            <img alt={t('common.resume')} src="/assets/icons/pen.svg" />
                            {t('menu.resume')}
                        </Link>
                    </li>
                )}
                <li className="menu__link">
                    <button
                        onClick={() => { openMenu(false); restartForm(); }}
                        type="button"
                    >
                        <img alt={t('common.back')} src="/assets/icons/history.svg" />
                        {t('menu.restart')}
                    </button>
                </li>
                {user.lastFormCompleteId && (
                    <li className="menu__link">
                        <button
                            onClick={() => { openMenu(false); push(ROUTES.results); }}
                            type="button"
                        >
                            <img alt={t('common.previousResults')} src="/assets/icons/statistic.svg" />
                            {t('menu.results')}
                        </button>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Menu;
