import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getDetailedSituations, markStepComplete, saveStory } from 'actions';
import Container from 'components/layout/Container';
import MainWithSteps, { SCROLL_ANCHOR_ID } from 'components/layout/MainWithSteps';
import Panel from 'components/layout/Panel';
import DetailedSituationForm from 'components/Step3/Part2/Form';
import SituationCard from 'components/Step3/SituationCard';
import StepList from 'components/StepDescription/StepList';
import { useAppData } from 'hooks/useAppData';
import useLocalizedHistoryPush from 'hooks/useLocalizedHistoryPush';
import LanguageContext from 'Language/LanguageContext';
import WithCurrentForm from 'providers/WithCurrentForm';
import goToStep from 'utils/goToStep';
import { skipStep3 } from 'utils/step3';

const Part2 = () => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const { API_URL } = useAppData();
    const { detailedSituations } = useSelector((state) => state.currentForm);
    const { thirdStepComplete } = useSelector(({ currentForm }) => currentForm);
    const [softSkillIndex, setSoftSkillIndex] = useState(0);
    const push = useLocalizedHistoryPush();
    const dispatch = useDispatch();
    const [step1Uncomplete, setStep1Uncomplete] = useState(false);

    useEffect(() => {
        dispatch(getDetailedSituations(API_URL));
    }, []);

    useEffect(() => {
        if (thirdStepComplete) {
            goToStep(push, 4);
        }
    }, [thirdStepComplete]);

    useEffect(() => {
        if (detailedSituations !== null) {
            // if there are not 3 situations, it means that step 1 is uncomplete
            if (detailedSituations.length !== 3) {
                setStep1Uncomplete(true);
            }
            setSoftSkillIndex(detailedSituations.findIndex(({ details }) => details === null));
        }
    }, [detailedSituations]);

    if (detailedSituations === null) {
        return null;
    }

    if (detailedSituations.length === 0 || step1Uncomplete) {
        return <Redirect to="/account" />;
    }

    const sendStory = async (values) => {
        const data = {
            details: values,
            detailedSituationId: detailedSituations[softSkillIndex].id,
        };
        await dispatch(saveStory(API_URL, data));

        if (softSkillIndex < detailedSituations.length - 1) {
            setSoftSkillIndex(softSkillIndex + 1);
            // scroll to the top of the form
            document.getElementById(SCROLL_ANCHOR_ID).scrollIntoView();
        } else {
            await dispatch(markStepComplete(API_URL, { step: 3 }, language));
        }
    };

    const { situation } = detailedSituations[softSkillIndex];

    return (
        <Container>
            <Panel>
                <StepList step={3} />
            </Panel>
            <MainWithSteps
                currentStep={3}
                subtitle={t('step3.part2.situation.intro')}
                title={t(
                    'step3.part2.title.text2',
                    { softSkill: t(`softSkill.${situation.metaCapability.softSkill.key}`) },
                )}
            >
                <SituationCard
                    index={softSkillIndex + 1}
                    situation={situation.key}
                />
                <DetailedSituationForm
                    onSkip={() => skipStep3({ dispatch, push, API_URL, language })}
                    onSubmit={sendStory}
                />
            </MainWithSteps>
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm><Part2 {...props} /></WithCurrentForm>
);
