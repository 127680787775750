import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.scss';

const AdminTable = ({ stats }) => {
    const { t } = useTranslation();

    return (
        <>
            <table className="admin-table">
                <thead className="admin-table__header">
                    <tr>
                        <th>{t('admin.stat.selectedAchievement.table.achievement')}</th>
                        <th>{t('admin.stat.selectedAchievement.table.count')}</th>
                        <th>{t('admin.stat.selectedAchievement.table.countTop3')}</th>
                    </tr>
                </thead>
                <tbody>
                    {stats.selectedAchievementStats.map(({ key, count, countTop3 }) => (
                        <tr key={key}>
                            <td>{t(`achievement.${key}`)}</td>
                            <td>{count}</td>
                            <td>{countTop3}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table className="admin-table">
                <thead className="admin-table__header">
                    <tr>
                        <th>{t('admin.stat.experiencedSituation.table.situation')}</th>
                        <th>{t('admin.stat.experiencedSituation.table.count')}</th>
                    </tr>
                </thead>
                <tbody>
                    {stats.experiencedSituationStats.map(({ key, count }) => (
                        <tr key={key}>
                            <td>{t(`situation.${key}`)}</td>
                            <td>{count}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table className="admin-table">
                <thead className="admin-table__header">
                    <tr>
                        <th>{t('admin.stat.softSkillScore.table.softSkill')}</th>
                        <th>{t('admin.stat.softSkillScore.table.count')}</th>
                    </tr>
                </thead>
                <tbody>
                    {stats.softSkillScoreStats.map(({ key, countTop3 }) => (
                        <tr key={key}>
                            <td>{t(`softSkill.${key}`)}</td>
                            <td>{countTop3}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

AdminTable.propTypes = {
    stats: PropTypes.shape({
        experiencedSituationStats: PropTypes.arrayOf(PropTypes.shape({
            count: PropTypes.number.isRequired,
            key: PropTypes.string.isRequired,
        })).isRequired,
        selectedAchievementStats: PropTypes.arrayOf(PropTypes.shape({
            count: PropTypes.number.isRequired,
            countTop3: PropTypes.number.isRequired,
            key: PropTypes.string.isRequired,
        })).isRequired,
        softSkillScoreStats: PropTypes.arrayOf(PropTypes.shape({
            countTop3: PropTypes.number.isRequired,
            key: PropTypes.string.isRequired,
        })).isRequired,
    }),
};

export default AdminTable;
