import { SubmissionError } from 'redux-form';
import { GET, PATCH, POST } from '../utils/http';

export const SET_FORM = 'SET_FORM';
export const SET_FORM_STATUS = 'SET_FORM_STATUS';
export const CLEAN_FORM = 'CLEAN_FORM';
export const UPDATE_EXPERIENCED_SITUATION = 'UPDATE_EXPERIENCED_SITUATION';
export const SET_ACHIEVEMENTS = 'SET_ACHIEVEMENTS';
export const UPDATE_SELECTED_ACHIEVEMENTS = 'UPDATE_SELECTED_ACHIEVEMENTS';
export const SET_SELECTED_ACHIEVEMENT = 'SET_SELECTED_ACHIEVEMENT';
export const SET_SELECTED_ACHIEVEMENT_TOP3_VALUE = 'SET_SELECTED_ACHIEVEMENT_TOP3_VALUE';
export const CLEAN_SELECTED_ACHIEVEMENTS = 'CLEAN_SELECTED_ACHIEVEMENTS';
export const SET_SOFT_SKILLS_TOP3 = 'SET_SOFT_SKILLS_TOP3';
export const SET_SITUATION_CHOICES = 'SET_SITUATION_CHOICES';
export const SET_DETAILED_SITUATIONS = 'SET_DETAILED_SITUATIONS';
export const SAVE_STORY = 'SAVE_STORY';

const storeForm = (form, dispatch) => {
    dispatch({ type: SET_FORM, form });
};

export const createForm = (API_URL) => async (dispatch) => {
    try {
        const newForm = await POST(`${API_URL}/form/`);
        storeForm(newForm, dispatch);
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const recoverForm = (API_URL) => async (dispatch) => {
    try {
        const lastForm = await GET(`${API_URL}/form/last-form`);
        if (lastForm) {
            storeForm(lastForm, dispatch);
        }
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getAllAchievements = (API_URL) => async (dispatch) => {
    try {
        const achievements = await GET(`${API_URL}/form/achievements`);
        dispatch({ type: SET_ACHIEVEMENTS, achievements });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

// add or remove the achievement. Update only locally (in store)
export const setSelectedAchievement = ({ achievement, value }) => async (dispatch) => {
    try {
        dispatch({ type: SET_SELECTED_ACHIEVEMENT, achievement, value });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getSelectedAchievements = (API_URL) => async (dispatch) => {
    try {
        const selectedAchievements = await GET(`${API_URL}/form/selected-achievements`);
        dispatch({ type: UPDATE_SELECTED_ACHIEVEMENTS, selectedAchievements });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

// request api to update the db
export const saveSelectedAchievements = (API_URL, achievementIds) => async (dispatch) => {
    try {
        await POST(`${API_URL}/form/selected-achievements`, { achievementIds });
        dispatch({ type: CLEAN_SELECTED_ACHIEVEMENTS });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

// add or remove the achievement. Update only locally (in store)
export const setSelectedAchievementTop3Value = ({ id, value }) => async (dispatch) => {
    try {
        dispatch({ type: SET_SELECTED_ACHIEVEMENT_TOP3_VALUE, id, value });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

// request api to update the db
export const saveTop3SelectedAchievements = (API_URL, selectedAchievementIds) => async () => {
    try {
        await PATCH(`${API_URL}/form/selected-achievements-top-3`, { selectedAchievementIds });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const updateExperiencedSituation = (API_URL, values) => async (dispatch) => {
    try {
        const experiencedSituation = await PATCH(`${API_URL}/form/experienced-situation`, { values });
        dispatch({ type: UPDATE_EXPERIENCED_SITUATION, experiencedSituation });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getSoftSkillsTop = (API_URL) => async (dispatch) => {
    try {
        const softSkillsTop3 = await GET(`${API_URL}/form/soft-skills/top`);
        dispatch({ type: SET_SOFT_SKILLS_TOP3, softSkillsTop3 });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getSituationChoices = (API_URL) => async (dispatch) => {
    try {
        const situations = await GET(`${API_URL}/form/soft-skills/situation-choices`);
        dispatch({ type: SET_SITUATION_CHOICES, situations });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const selectSituation = (API_URL, situationId) => async () => {
    try {
        await POST(`${API_URL}/form/detailed-situation`, { situationId });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getDetailedSituations = (API_URL) => async (dispatch) => {
    try {
        const detailedSituations = await GET(`${API_URL}/form/detailed-situations`);
        dispatch({ type: SET_DETAILED_SITUATIONS, detailedSituations });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const saveStory = (API_URL, values) => async () => {
    try {
        await PATCH(`${API_URL}/form/detailed-situation`, values);
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const markStepComplete = (API_URL, values) => async (dispatch) => {
    try {
        const { step } = values;
        let result;

        switch (step) {
            case 1:
                result = await PATCH(`${API_URL}/form/complete-step-1`, values);
                break;
            case 2:
                result = await PATCH(`${API_URL}/form/complete-step-2`, values);
                break;
            case 3:
                result = await PATCH(`${API_URL}/form/complete-step-3`, values);
                break;
            default:
                throw new Error('currentForm::markStepComplete Step not recognized');
        }

        dispatch({ type: 'SET_USER', user: result.user });
        storeForm(result.form, dispatch);
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getFormSoftSkillsTop = (API_URL, formId) => async (dispatch) => {
    try {
        const softSkillsTop3 = await GET(`${API_URL}/form/${formId}/soft-skills/top`);
        dispatch({ type: SET_SOFT_SKILLS_TOP3, softSkillsTop3 });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const redoStep3 = (API_URL) => async (dispatch) => {
    try {
        const { form, user } = await PATCH(`${API_URL}/form/redo-step-3`);
        storeForm(form, dispatch);
        dispatch({ type: 'SET_USER', user });
    } catch (e) {
        console.error(e);
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const cleanForm = () => ({
    type: CLEAN_FORM,
});
