import { useContext, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LanguageContext from './LanguageContext';
import Account from 'routes/Account';
import Admin from 'routes/Admin';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import Form from 'routes/Form';
import Home from 'routes/Home';
import Confidentiality from 'routes/Legal/Confidentiality';
import LegalNotices from 'routes/Legal/LegalNotices';
import TermOfServices from 'routes/Legal/TermOfServices';
import LoginRedirect from 'routes/LoginRedirect';
import NotFound from 'routes/NotFound';
import PasswordReset from 'routes/PasswordReset';
import Pdf from 'routes/Pdf';
import Results from 'routes/Results';
import ShareTop3Skills from 'routes/ShareTop3Skills';
import SoftSkillPage from 'routes/SoftSkill';
import Step1Ending from 'routes/Step1/Ending';
import Step1Part1 from 'routes/Step1/Part1';
import Step1Part2 from 'routes/Step1/Part2';
import Step2 from 'routes/Step2';
import Step2Ending from 'routes/Step2/Ending';
import Step3Part1 from 'routes/Step3/Part1';
import Step3Part2 from 'routes/Step3/Part2';

const LocalizedRoutes = () => {
    const { language } = useContext(LanguageContext);

    const Routes = useMemo(() => (
        <Switch>
            <Route component={Home} path={`/${language}`} exact />
            <Route component={LoginRedirect} path={`/${language}/login-redirect`} exact />
            <Route component={PasswordReset} path={`/${language}/password-reset/:token`} exact />
            <Route component={LegalNotices} path={`/${language}/legalnotices`} exact />
            <Route component={Confidentiality} path={`/${language}/confidentiality`} exact />
            <Route component={TermOfServices} path={`/${language}/tos`} exact />
            <Route component={Pdf} path={`/${language}/pdf/:formId`} exact />
            <Route component={ShareTop3Skills} path={`/${language}/top-3-soft-skill/:formId`} exact />
            {/* ACCOUNT */}
            <AuthenticatedRoute component={Account} path={`/${language}/account`} exact />
            <AuthenticatedRoute component={Admin} path={`/${language}/admin`} exact />
            {/* STEP 1 */}
            <AuthenticatedRoute component={Step1Part1} path={`/${language}/step/1/part/1`} exact />
            <AuthenticatedRoute component={Step1Part2} path={`/${language}/step/1/part/2`} exact />
            <AuthenticatedRoute component={Step1Ending} path={`/${language}/step/1/ending`} exact />
            {/* STEP 2 */}
            <AuthenticatedRoute component={Step2} path={`/${language}/step/2`} exact />
            <AuthenticatedRoute component={Step2Ending} path={`/${language}/step/2/ending`} exact />
            {/* STEP 3 */}
            <AuthenticatedRoute component={Step3Part1} path={`/${language}/step/3/part/1`} exact />
            <AuthenticatedRoute component={Step3Part2} path={`/${language}/step/3/part/2`} exact />
            {/* RESULTS */}
            <AuthenticatedRoute component={Form} path={`/${language}/form`} exact />
            <AuthenticatedRoute component={Results} path={`/${language}/results`} exact />
            <Route component={NotFound} path={`/${language}/not-found`} status={404} exact />
            <Route path={`/${language}/last-results`} exact>
                <Redirect to={`/${language}/results`} />
            </Route>
            <AuthenticatedRoute
                component={SoftSkillPage}
                path={`/${language}/results/soft-skill/:softSkillSlug`}
                exact
            />
            <Route component={NotFound} path={`/${language}/not-found`} status={404} exact />
            <Route component={NotFound} path={`/${language}/*`} status={404} exact />
        </Switch>
    ), [language]);

    return Routes;
};

export default LocalizedRoutes;
