import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactSlider from 'react-slider';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/common/Button';
import ChoiceField from 'components/common/form/ChoiceField';
import GenderField from 'components/common/form/GenderField';
import SelectField from 'components/common/form/SelectField';
import RotiQuestion from 'components/EndForm/RotiQuestion';
import {
    AGE_CAT,
    DIPLOMA_LIST,
    GENDER_LIST,
    JOBS_LIST,
    REASONS_LIST,
    ROTI_QUESTIONS,
    STATUS_LIST,
} from 'utils/constants';
import './style.scss';

const validate = (values) => {
    const errors = {
        reasons: {},
    };

    if (!values.gender) {
        errors.gender = 'Required';
    }
    if (!values.status) {
        errors.status = 'Required';
    }
    if (!values.diploma) {
        errors.diploma = 'Required';
    }
    if ((!values.reasons)
        || (values.reasons && (values.reasons && !Object.values(values.reasons).includes(true)))) {
        errors.reasons.knowledge = 'Required';
    }
    if (!values.satisfaction) {
        errors.satisfaction = 'Required';
    }
    if (!values.difficulty) {
        errors.difficulty = 'Required';
    }
    if (!values.aestheticism) {
        errors.aestheticism = 'Required';
    }
    if (!values.duration) {
        errors.duration = 'Required';
    }
    if (!values.content) {
        errors.content = 'Required';
    }

    return errors;
};

/* eslint-disable-next-line import/no-mutable-exports */
let EndForm = ({ initialValues, invalid, handleSubmit, submitting }) => {
    const { t } = useTranslation();

    if (initialValues && initialValues.ageRange === null) {
        /* eslint-disable-next-line no-param-reassign */
        initialValues.ageRange = 1; // set default value to ageRange
    }

    const renderTooltip = (props, state) => (
        <div {...props}>
            <div className="slider-form__value">{AGE_CAT[state.valueNow].toString()}</div>
        </div>
    );

    return (
        <form className="end-form__container" method="post" onSubmit={handleSubmit}>
            <div className="form-group">
                <h2>{t('sociodemo.title')}</h2>
                <p className="label">{t('sociodemo.field.age.title')}</p>
                <Field
                    // TODO PCD: Refacto to delete eslint-disable
                    // eslint-disable-next-line react/no-unstable-nested-components
                    component={({ input: { onChange, value } }) => (
                        <ReactSlider
                            className="slider-form"
                            defaultValue={value}
                            markClassName="slider-form__mark"
                            max={5}
                            min={0}
                            onAfterChange={(v) => onChange(v)}
                            renderThumb={(props, state) => renderTooltip(props, state)}
                            thumbClassName="slider-form__thumb"
                            trackClassName="slider-form__track"
                        />
                    )}
                    defaultValue="1"
                    format={null}
                    id="age"
                    name="ageRange"
                />
            </div>
            <div className="form-group">
                <p className="label">{t('sociodemo.field.gender.title')}</p>
                <div className="gender__wrapper">
                    {GENDER_LIST.map((status, i) => (
                        <Field
                            key={status.key}
                            component={GenderField}
                            id={`gender-${i}`}
                            label={t(status.name)}
                            name="gender"
                            options={GENDER_LIST}
                            type="radio"
                            value={status.key}
                        />
                    ))}
                </div>
            </div>
            <div className="form-group">
                <p className="label">{t('sociodemo.field.status.title')}</p>
                <div className="choicefield__wrapper">
                    {STATUS_LIST.map((status, i) => (
                        <Field
                            key={status.key}
                            component={ChoiceField}
                            id={`status-${i}`}
                            label={t(status.name)}
                            name="status"
                            type="radio"
                            value={status.key}
                        />
                    ))}
                </div>
            </div>
            <div className="form-group">
                <p className="label">{t('sociodemo.field.diploma.title')}</p>
                <div className="choicefield__wrapper">
                    {DIPLOMA_LIST.map((status, i) => (
                        <Field
                            key={status.key}
                            component={ChoiceField}
                            id={`diploma-${i}`}
                            label={t(status.name)}
                            name="diploma"
                            type="radio"
                            value={status.key}
                        />
                    ))}
                </div>
            </div>
            <div className="form-group">
                {/* eslint-disable-next-line react/no-danger */}
                <p className="label">{t('sociodemo.field.reason.title')}</p>
                <div className="choicefield__wrapper">
                    {REASONS_LIST.map((status, i) => (
                        <Field
                            key={status.key}
                            component={ChoiceField}
                            id={`reason-${i}`}
                            label={t(status.name)}
                            name={`reasons.${status.key}`}
                            type="checkbox"
                            value={status.key}
                        />
                    ))}
                </div>
            </div>
            <div className="form-group">
                <Field
                    component={SelectField}
                    label={t('sociodemo.field.job.title')}
                    name="job"
                    options={JOBS_LIST.map(({ key, name }) => ({ label: t(name), value: key }))}
                    placeholder={t('sociodemo.job.placeholder')}
                />
            </div>
            <div className="roti-section">
                <h2>{t('roti.title')}</h2>
                {ROTI_QUESTIONS.map((rotiQuestion) => (
                    <RotiQuestion key={rotiQuestion} label={rotiQuestion} />
                ))}
            </div>
            <Button
                classes="button--block button--black base-margin"
                disabled={invalid}
                submitting={submitting}
                text={t('sociodemo.submit')}
                submit
            />
        </form>
    );
};

EndForm.propTypes = {
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.shape(),
    invalid: PropTypes.bool,
    submitting: PropTypes.bool.isRequired,
};

EndForm = reduxForm({
    form: 'endForm',
    validate,
})(EndForm);

EndForm = connect(
    (state) => ({
        initialValues: state.user,
    }),
)(EndForm);

export default EndForm;
