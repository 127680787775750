import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { checkUserHasUncompletedForm } from '../actions';
import { ROUTES } from '../utils/constants';

const computeRedirection = (formStatus) => {
    if (!formStatus) { return ''; }
    if (!formStatus.firstStepComplete) { return ROUTES.step1.part1; }
    if (!formStatus.secondStepComplete) { return ROUTES.step2.main; }
    if (!formStatus.thirdStepComplete) { return ROUTES.step3.part1; }

    return ROUTES.results;
};

const useUncompleteFormRedirection = (API_URL) => {
    const dispatch = useDispatch();
    const userHasUncompletedForm = useSelector((state) => state.user.hasUncompletedForm);
    const formStatus = useSelector(({ currentForm }) => currentForm?.formStatus, shallowEqual);

    useEffect(() => {
        dispatch(checkUserHasUncompletedForm(API_URL));
    }, [userHasUncompletedForm]);

    const formRedirection = computeRedirection(formStatus);

    return { userHasUncompletedForm, formRedirection };
};

export default useUncompleteFormRedirection;
