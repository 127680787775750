import { SubmissionError } from 'redux-form';
import { GET } from '../utils/http';

export const CLEAR_DETAILED_SITUATION = 'CLEAR_DETAILED_SITUATION';
export const SET_DETAILED_SITUATION = 'SET_DETAILED_SITUATION';
export const SET_RESULT = 'SET_RESULT';

export const clearDetailedSituation = () => ({
    type: CLEAR_DETAILED_SITUATION,
});

export const getDetailedSituation = (API_URL, softSkillSlug) => async (dispatch) => {
    try {
        const detailedSituation = await GET(`${API_URL}/result/detailed-situation/${softSkillSlug}`);
        dispatch({ type: SET_DETAILED_SITUATION, detailedSituation });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const getFormResults = (API_URL) => async (dispatch) => {
    try {
        const { selectedAchievements, softSkillsTop3, canRedoStep3 } = await GET(`${API_URL}/result`);
        dispatch({ type: SET_RESULT, selectedAchievements, softSkillsTop3, canRedoStep3 });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};
