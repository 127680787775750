import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import LanguageContext from 'Language/LanguageContext';
import LocalizedRoutes from 'Language/LocalizedRoutes';
import RedirectToLocalizedRoute from 'Language/RedirectToLocalizedRoute';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import MissingLanguage from 'routes/MissingLanguage';

const Routes = () => {
    const { allowedLanguages } = useContext(LanguageContext);

    return (
        <Switch>
            <AuthenticatedRoute
                component={MissingLanguage}
                path="/missing-language"
                exact
            />
            <Route path={`/:lang(${allowedLanguages.join('|')})`}>
                <LocalizedRoutes />
            </Route>
            <Route path="*">
                <RedirectToLocalizedRoute />
            </Route>
        </Switch>
    );
};

export default Routes;
