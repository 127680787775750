import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SituationCard from 'components/Step3/SituationCardCheckbox';
import './style.scss';

const Selection = ({ situations, selectedSituationId, onSelectSituation }) => {
    const { t } = useTranslation();

    return (
        <>
            <span className="step3-part1-selection__description">{t('step3.part1.selection')}</span>
            <div className="step3-part1-selection__cards-container">
                {situations.map(({ id, key }, i) => (
                    <SituationCard
                        key={id}
                        index={i + 1}
                        isSelected={selectedSituationId === id}
                        onClick={() => onSelectSituation(id)}
                        situation={key}
                    />
                ))}
            </div>
        </>
    );
};

Selection.propTypes = {
    onSelectSituation: PropTypes.func.isRequired,
    selectedSituationId: PropTypes.string,
    situations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        metaCapabilityId: PropTypes.string.isRequired,
    }).isRequired),
};

export default Selection;
