import PropTypes from 'prop-types';
import './style.scss';

const ChoiceField = ({ input, id, label, type }) => (
    <label
        className={
            `choicefield
            ${input.checked ? 'choicefield--active' : null}`
        }
        htmlFor={id}
    >
        {label}
        <input {...input} id={id} type={type} />
    </label>
);

ChoiceField.propTypes = {
    id: PropTypes.string.isRequired,
    input: PropTypes.shape({
        checked: PropTypes.bool,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    }),
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
};

export default ChoiceField;
