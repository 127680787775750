/* eslint-disable max-len */
import PropTypes from 'prop-types';

const IconClose = ({ color = 'black', height = 14 }) => (
    <svg fill="none" height={height} viewBox="0 0 14 14" width={height} xmlns="http://www.w3.org/2000/svg">
        <path
            clipRule="evenodd"
            d="M2.20711 0.792893C1.81658 0.402369 1.18342 0.402369 0.792893 0.792893C0.402369 1.18342 0.402369 1.81658 0.792893 2.20711L5.58584 7.00006L0.79301 11.7929C0.402485 12.1834 0.402485 12.8166 0.79301 13.2071C1.18353 13.5976 1.8167 13.5976 2.20722 13.2071L7.00006 8.41427L11.7929 13.2071C12.1834 13.5976 12.8166 13.5976 13.2071 13.2071C13.5976 12.8166 13.5976 12.1834 13.2071 11.7929L8.41427 7.00006L13.2072 2.20711C13.5977 1.81658 13.5977 1.18342 13.2072 0.792893C12.8167 0.402369 12.1835 0.402369 11.793 0.792893L7.00006 5.58584L2.20711 0.792893Z"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
);

IconClose.propTypes = {
    color: PropTypes.string,
    height: PropTypes.number,
};

export default IconClose;
