import PropTypes from 'prop-types';
import './style.scss';

const ProgressBar = ({ currentStepIndex, numberOfSteps }) => {
    const rowsFilled = [];
    const rows = [];
    for (let i = 0; i < numberOfSteps; i++) {
        if (i <= currentStepIndex) {
            rowsFilled.push(<div key={`step-${i}`} className="progressbar__circle--filled" />);
        } else {
            rows.push(<div key={`step-${i}`} className="progressbar__circle" />);
        }
    }

    return (
        <div className="progressbar__circle-container">
            <div className="progressbar__circle-filled">{rowsFilled}</div>
            {rows}
        </div>
    );
};

ProgressBar.propTypes = {
    currentStepIndex: PropTypes.number.isRequired,
    numberOfSteps: PropTypes.number.isRequired,
};

export default ProgressBar;
