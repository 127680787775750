import { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteAccount as deleteAccountAction } from 'actions';
import DeleteAccountModal from 'components/DeleteAccountModal';
import { useAppData } from 'hooks/useAppData';
import useLogout from 'hooks/useLogout';
import { useMenu } from 'hooks/useMenu';
import LanguageContext from 'Language/LanguageContext';

import './style.scss';

const SecondaryMenu = () => {
    const { t } = useTranslation();
    const { openMenu, menuIsOpen } = useMenu();
    const logout = useLogout();
    const dispatch = useDispatch();
    const { API_URL } = useAppData();
    const { user } = useSelector((state) => state);
    const { language } = useContext(LanguageContext);

    const showError = () => {
        // eslint-disable-next-line no-alert
        window.alert(t('error.account.cannot_delete'));
    };

    const deleteAccount = async () => {
        const deletionIsSuccessfull = await dispatch(deleteAccountAction(API_URL));
        if (deletionIsSuccessfull) {
            logout();
        } else {
            showError();
        }
    };

    const onDeleteAccount = () => {
        confirmAlert({
            /* eslint-disable-next-line */
            customUI: ({ onClose }) => (
                <DeleteAccountModal onClose={onClose} onConfirm={deleteAccount} />
            ),
        });
    };

    return (
        <ul className="secondary-menu">
            {(menuIsOpen && user) ? (
                <>
                    <li className="secondary-menu__link">
                        <button
                            onClick={() => { openMenu(false); logout(); }}
                            type="button"
                        >
                            {t('menu.logout')}
                        </button>
                    </li>
                    <li className="secondary-menu__link">
                        <button
                            onClick={() => { openMenu(false); onDeleteAccount(); }}
                            type="button"
                        >
                            {t('menu.deleteAccount')}
                        </button>
                    </li>
                </>
            ) : (
                <>
                    <li className="secondary-menu__link">
                        <Link
                            onClick={() => { openMenu(false); }}
                            title={t('menu.legalNotices')}
                            to={`/${language}/legalnotices`}
                        >
                            {t('menu.legalNotices')}
                        </Link>
                    </li>
                    <li className="secondary-menu__link">
                        <Link
                            onClick={() => { openMenu(false); }}
                            title={t('menu.termsOfService')}
                            to={`/${language}/tos`}
                        >
                            {t('menu.termsOfService')}
                        </Link>
                    </li>
                    <li className="secondary-menu__link">
                        <Link
                            onClick={() => { openMenu(false); }}
                            title={t('menu.confidentiality')}
                            to={`/${language}/confidentiality`}
                        >
                            {t('menu.confidentiality')}
                        </Link>
                    </li>
                </>
            )}
        </ul>
    );
};

export default SecondaryMenu;
