import {
    CLEAN_USER,
    SET_USER,
    SET_USER_HAS_UNCOMPLETED_FORM,
    SET_USER_LANGUAGE,
} from 'actions/user';

const initialState = null;

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case SET_USER:
            return payload.user;
        case SET_USER_LANGUAGE:
            return {
                ...state,
                language: payload.language,
            };
        case SET_USER_HAS_UNCOMPLETED_FORM:
            return {
                ...state,
                hasUncompletedForm: payload.hasUncompletedForm,
            };
        case CLEAN_USER:
            return null;
        default:
            return state;
    }
};

export default reducer;
