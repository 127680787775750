import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import ChoiceField from 'components/common/form/ChoiceField';
import { ROTI_ANSWERS } from 'utils/constants';

import './style.scss';

const RotiQuestion = ({ label }) => {
    const { t } = useTranslation();

    return (
        <div className="form-group">
            <p className="label small">{t(`roti.${label}.label`)}</p>
            <div className="choicefield__wrapper roti__choicefield-wrapper">
                {ROTI_ANSWERS.map((roti, i) => (
                    <Field
                        key={roti.toString()}
                        component={ChoiceField}
                        id={`${label}-${i}`}
                        label={roti.toString()}
                        name={label}
                        type="radio"
                        value={roti.toString()}
                    />
                ))}
            </div>
            <div className="roti__choicefield-label-wrapper">
                <span>{t('roti.answer.left')}</span>
                <span>{t('roti.answer.middle')}</span>
                <span>{t('roti.answer.right')}</span>
            </div>
        </div>
    );
};

RotiQuestion.propTypes = {
    label: PropTypes.string.isRequired,
};

export default RotiQuestion;
