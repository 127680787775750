import { Trans, useTranslation } from 'react-i18next';
import './style.scss';

const PageInterviewAdvices = () => {
    const { t } = useTranslation();

    const items = [1, 2, 3, 4, 5, 6, 7];

    return (
        <div className="page-interview-advices">
            <h2 className="pdf__title">{t('pdf.pageInterviewAdvices.title')}</h2>
            <div className="pdf__text--justify">
                <p className="pdf__p"><Trans i18nKey="pdf.pageInterviewAdvices.text1" /></p>
                <p className="pdf__p"><Trans i18nKey="pdf.pageInterviewAdvices.text2" /></p>
            </div>
            <ul className="pdf__text--justify page-interview-advices__list">
                {items.map((index) => (
                    <li key={index}>
                        <span className="strong">
                            {t(`pdf.pageInterviewAdvices.item${index}.title`)}
                        </span>
                        &nbsp;
                        <Trans i18nKey={`pdf.pageInterviewAdvices.item${index}.description`} />
                    </li>
                ))}
                <li><Trans i18nKey="pdf.pageInterviewAdvices.conclusion" /></li>
            </ul>
        </div>
    );
};

export default PageInterviewAdvices;
