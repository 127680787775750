import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'components/common/Modal';

const DeleteAccountModal = ({ onClose, onConfirm }) => {
    const { t } = useTranslation();

    return (
        <Modal closeModal={onClose} title={t('deleteAccount.title')} fullscreen>
            <p>{t('deleteAccount.text')}</p>
            <p>{t('deleteAccount.text.previousTools')}</p>
            <button
                className="button button--block button--black base-margin"
                onClick={() => { onClose(); onConfirm(); }}
                type="button"
            >
                {t('deleteAccount.yes')}
            </button>
            <button
                className="button button--block button--grey base-margin"
                onClick={onClose}
                type="button"
            >
                {t('deleteAccount.cancel')}
            </button>
        </Modal>
    );
};

DeleteAccountModal.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
};

export default DeleteAccountModal;
