import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import auth from '../jt-client/reducers/auth';
import currentForm from './currentForm';
import result from './result';
import user from './user';

const rootReducer = combineReducers({
    auth,
    currentForm,
    form,
    result,
    user,
});

export default rootReducer;
