import { useEffect, useMemo, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { updateExperiencedSituation } from 'actions';
import ProgressBar from 'components/common/ProgressBar';
import Navigation from 'components/Navigation';
import SituationCard from 'components/Step2/SituationCard';
import DescriptionExercise from 'components/StepDescription/DescriptionExercise';
import Instructions from 'components/StepDescription/Instructions';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import './style.scss';

// animation speed
const SLIDER_ANIMATION_SPEED = 500;
// time before the animation activates
const SLIDER_ANIMATION_TIMEOUT = 300;
// the timeout is used to prevent the user to slide too quickly (while the slider animation is running) and induce bugs
const SLIDER_ACTION_TIMEOUT = SLIDER_ANIMATION_SPEED + SLIDER_ANIMATION_TIMEOUT + 100;

const getNumberedExperiencedSituations = (initialSituations) => {
    const situations = [...initialSituations];
    let currentSkill = situations[0]
        .situation
        .metaCapability
        .softSkill
        .key;
    let currentSkillStartIndex = 0;

    for (let i = 0; i < situations.length; i++) {
        if (situations[i].situation.metaCapability.softSkill.key !== currentSkill) {
            currentSkill = situations[i].situation.metaCapability.softSkill.key;
            currentSkillStartIndex = i;
        }

        situations[i].number = (i - currentSkillStartIndex) + 1;
    }

    return situations;
};

const StepTwoForm = () => {
    const { t } = useTranslation();
    const { experiencedSituations } = useSelector((state) => state.currentForm);
    const sliderRef = useRef();
    const push = useLocalizedHistoryPush();
    const { API_URL } = useAppData();
    const dispatch = useDispatch();
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [lastSlideIndexAnswered, setLastSlideIndexAnswered] = useState(0);
    const situations = useMemo(() => getNumberedExperiencedSituations(experiencedSituations), [experiencedSituations]);
    const [isAnimating, setIsAnimating] = useState(false);
    const currentSoftSkillKey = experiencedSituations[currentSlideIndex]
        .situation
        .metaCapability
        .softSkill
        .key;

    const showInstructions = () => {
        confirmAlert({
            // TODO PCD: Refacto to delete eslint-disable
            // eslint-disable-next-line react/no-unstable-nested-components
            customUI: ({ onClose }) => (
                <Instructions onClose={onClose}>
                    <DescriptionExercise step={2} />
                </Instructions>
            ),
        });
    };

    useEffect(() => {
        let currentExperiencedSituationIndex = experiencedSituations.findIndex(
            (experiencedSituation) => experiencedSituation.experienced === null,
        );
        // if no answer is null, the form is completed, so we set the currentSlideIndex to the situation
        if (currentExperiencedSituationIndex === -1) {
            currentExperiencedSituationIndex = experiencedSituations.length - 1;
        }
        setCurrentSlideIndex(currentExperiencedSituationIndex);
        setLastSlideIndexAnswered(currentExperiencedSituationIndex - 1);
        sliderRef.current.slickGoTo(currentExperiencedSituationIndex);

        if (currentExperiencedSituationIndex === 0) {
            showInstructions();
        }
    }, []);

    // use a Set to remove duplicates
    const softSkills = [...new Set(experiencedSituations
        .map((experiencedSituation) => experiencedSituation.situation.metaCapability.softSkill.key)),
    ];

    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: 1,
    };

    const onAnswer = (experiencedSituation) => (value) => {
        if (isAnimating) {
            return;
        }
        dispatch(updateExperiencedSituation(API_URL, {
            id: experiencedSituation.id,
            experienced: value,
        }));
        if (currentSlideIndex + 1 < experiencedSituations.length) {
            setIsAnimating(true);
            setTimeout(() => { setIsAnimating(false); }, SLIDER_ACTION_TIMEOUT);
            setTimeout(() => {
                sliderRef.current.slickGoTo(currentSlideIndex + 1);
                setCurrentSlideIndex(currentSlideIndex + 1);
                if (lastSlideIndexAnswered < (currentSlideIndex + 1)) {
                    setLastSlideIndexAnswered(currentSlideIndex);
                }
            }, SLIDER_ANIMATION_TIMEOUT);
        } else {
            push('/step/2/ending');
        }
    };

    const onGoNext = () => {
        sliderRef.current.slickNext();
        setCurrentSlideIndex(currentSlideIndex + 1);
    };

    const onGoBack = () => {
        sliderRef.current.slickPrev();
        setCurrentSlideIndex(currentSlideIndex - 1);
    };

    return (
        <div className="step-two__container">
            <h2 className="progressbar__title">
                {t(`softSkill.${currentSoftSkillKey}`)}
            </h2>
            <div className="step-two__progressbar">
                <ProgressBar
                    currentStepIndex={softSkills.findIndex((softSkillKey) => softSkillKey === currentSoftSkillKey)}
                    numberOfSteps={softSkills.length}
                />
            </div>
            <div className="step-two__slider-container">
                <Slider ref={sliderRef} {...settings}>
                    {situations.map((situation) => (
                        <div key={situation}>
                            <SituationCard
                                number={situation.number}
                                onAnswer={onAnswer(situation)}
                                text={t(`situation.${situation.situation.key}`)}
                                value={situation.experienced}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
            <Navigation
                current={currentSlideIndex + 1}
                disableBack={currentSlideIndex === 0}
                disableNext={
                    currentSlideIndex === (lastSlideIndexAnswered + 1)
                    || currentSlideIndex === situations.length
                }
                onBack={onGoBack}
                onNext={onGoNext}
                total={situations.length}
            />
            <img alt="" className="step-two__illu" src={`/assets/illus/${currentSoftSkillKey}.svg`} aria-hidden />
        </div>
    );
};

export default StepTwoForm;
