import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ButtonIcon from 'components/common/ButtonIcon';
import './style.scss';

const MainWithBackButton = ({ className, children, isLarge = false, title = null, to }) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <main className="main-with-back-button__wrapper">
            <div className="main-with-back-button__button-container">
                <ButtonIcon
                    className="main-with-back-button__back-button"
                    onClick={to ? () => history.push(to) : () => history.goBack()}
                >
                    <img alt="←" src="/assets/icons/arrow-left.svg" />
                    <span>{t('common.back')}</span>
                </ButtonIcon>
            </div>
            <div className={classnames('main', className, { 'main--large': isLarge })}>
                {title ? <h1 className="title main-with-back-button__title">{title}</h1> : null}
                {children}
            </div>
        </main>
    );
};

MainWithBackButton.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    isLarge: PropTypes.bool,
    title: PropTypes.string,
    to: PropTypes.string,
};

export default MainWithBackButton;
