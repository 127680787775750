import { filter } from 'ramda';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { markStepComplete, saveTop3SelectedAchievements } from 'actions';
import Button from 'components/common/Button';
import Container from 'components/layout/Container';
import MainWithSteps from 'components/layout/MainWithSteps';
import Panel from 'components/layout/Panel';
import FormTop3Achievements from 'components/Step1/Part2/FormTop3Achievements';
import StepList from 'components/StepDescription/StepList';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import LanguageContext from 'Language/LanguageContext';
import WithCurrentForm from 'providers/WithCurrentForm';
import { ROUTES } from 'utils/constants';
import './style.scss';

const Part2 = () => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const dispatch = useDispatch();
    const { language } = useContext(LanguageContext);
    const push = useLocalizedHistoryPush();
    const { selectedAchievements } = useSelector((state) => state.currentForm);

    if (selectedAchievements === null) {
        return <Redirect to="/account" />;
    }

    const selectedAchievementIsTop3Filter = (achievement) => achievement.isTop3 === true;
    const numberOfSelectedAchievementsForTop3 = filter(selectedAchievementIsTop3Filter, selectedAchievements).length;
    const isStepValid = numberOfSelectedAchievementsForTop3 === 3;

    const onValidate = async () => {
        if (!isStepValid) {
            return;
        }
        dispatch(saveTop3SelectedAchievements(
            API_URL,
            filter(selectedAchievementIsTop3Filter, selectedAchievements)
                .map((selectedAchievement) => selectedAchievement.id),
        ));
        await dispatch(markStepComplete(API_URL, { step: 1 }, language));
        push(ROUTES.step1.ending);
    };

    return (
        <Container>
            <Panel>
                <StepList step={1} />
            </Panel>
            <MainWithSteps
                currentStep={1}
                footer={(
                    <Button
                        classes="button button--block-mobile button--black"
                        disabled={!isStepValid}
                        onClick={onValidate}
                        text={t('common.validate')}
                    />
                )}
                iconColor="white"
                textContext={t('step1.part2.title.text1')}
                title={t('step1.part2.title.text2')}
                isLarge
            >
                <FormTop3Achievements />
            </MainWithSteps>
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm><Part2 {...props} /></WithCurrentForm>
);
