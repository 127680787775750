import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createForm } from 'actions';
import Button from 'components/common/Button';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import Panel from 'components/layout/Panel';
import Description from 'components/StepDescription/Description';
import { useAppData } from 'hooks/useAppData';
import useUncompleteFormRedirection from 'hooks/useUncompleteFormRedirection';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import useWindowSize from 'hooks/useWindowSize';
import goToStep from 'utils/goToStep';
import './style.scss';

const Account = () => {
    const { API_URL } = useAppData();
    const dispatch = useDispatch();
    const isMobile = 'xs' === useWindowSize().size;
    const { t } = useTranslation();
    const { userHasUncompletedForm, formRedirection } = useUncompleteFormRedirection(API_URL);
    const push = useLocalizedHistoryPush();
    const { user } = useSelector((state) => state);

    useEffect(() => {
        // put back scroll that was remove on home when a modal is open
        document.body.setAttribute('class', '');
    }, []);

    const resumeUncompletedForm = () => {
        push(formRedirection);
    };

    const restartForm = async () => {
        await dispatch(createForm(API_URL));
        goToStep(push, 1);
    };

    return (
        <Container bgColor={isMobile ? 'white' : 'grey'}>
            <Panel>
                <Description
                    subtitle={t('menu.account.description')}
                    title={t('menu.title', { surname: user.surname })}
                />
            </Panel>
            <Main className="account__main">
                {/* eslint-disable-next-line react/no-danger */}
                <h1 dangerouslySetInnerHTML={{ __html: t('account.title') }} className="title account__title" />
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: t('account.text') }} className="account__instructions" />
                <div className="account__buttons">
                    {
                        userHasUncompletedForm && (
                            <Button
                                classes="button--outline base-margin"
                                onClick={resumeUncompletedForm}
                                text={t('account.action.start')}
                            />
                        )
                    }
                    <Button
                        classes="button--black base-margin"
                        onClick={restartForm}
                        text={t('account.action.new')}
                    />
                </div>
            </Main>
        </Container>
    );
};

export default Account;
