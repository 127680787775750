import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { checkToken, currentUser, getUser } from 'actions';
import { useAppData } from 'hooks/useAppData';
import useBrowserLanguage from 'hooks/useBrowserLanguage';

const AuthenticatedRoute = ({ children, ...rest }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { user } = useSelector((state) => state);
    const { API_URL } = useAppData();
    const history = useHistory();
    const browserLanguage = useBrowserLanguage();

    useEffect(() => {
        async function fetchToken() {
            try {
                const result = await dispatch(checkToken());
                if (!result || !result.success) {
                    history.push(`/${browserLanguage}`);
                } else {
                    await dispatch(currentUser());
                    if (!user) {
                        await dispatch(getUser(API_URL));
                    }
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false);
            }
        }
        fetchToken();
    }, []);

    if (loading) {
        return null;
    }

    return <Route {...rest}>{children}</Route>;
};

AuthenticatedRoute.propTypes = {
    children: PropTypes.node,
};

export default AuthenticatedRoute;
