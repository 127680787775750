/* eslint-disable max-len */
import PropTypes from 'prop-types';

const IconCheck = ({ color = 'black', height = 20 }) => (
    <svg fill="none" height={height} viewBox="0 0 20 20" width={height} xmlns="http://www.w3.org/2000/svg">
        <path
            clipRule="evenodd"
            d="M15.2406 4.56091C15.7575 4.0352 16.5955 4.0352 17.1123 4.56091C17.6292 5.08661 17.6292 5.93895 17.1123 6.46465L8.28882 15.439C7.77195 15.9647 6.93394 15.9647 6.41706 15.439L2.88765 11.8493C2.37078 11.3236 2.37078 10.4712 2.88765 9.94552C3.40452 9.41982 4.24254 9.41982 4.75941 9.94552L7.35294 12.5834L15.2406 4.56091Z"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
);

IconCheck.propTypes = {
    color: PropTypes.string,
    height: PropTypes.number,
};

export default IconCheck;
