import { useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import LanguageContext from './LanguageContext';

const RedirectToLocalizedRoute = () => {
    const { pathname } = useLocation();
    const { language, allowedLanguages } = useContext(LanguageContext);

    if (!allowedLanguages.includes(language)) {
        return <Redirect to="/missing-language" />;
    }

    if (pathname === '/') {
        return <Redirect to={`/${language}`} />;
    }

    return <Redirect to={`/${language}${pathname}`} />;
};

export default RedirectToLocalizedRoute;
