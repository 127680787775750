import { DEFAULT_LANGUAGE, extractLangFromIso639, isAValidLanguage } from 'utils/lang';

const useBrowserLanguage = () => {
    const userLang = navigator.language || navigator.userLanguage;
    const lang = extractLangFromIso639(userLang);

    return isAValidLanguage(lang) ? lang : DEFAULT_LANGUAGE;
};

export default useBrowserLanguage;
