import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import fieldPropTypes from 'utils/fieldPropTypes';
import './style.scss';

const SelectField = ({
    className = '',
    hasAllOption = false,
    input,
    isClearable = false,
    isSearchable = false,
    label,
    labelHidden = false,
    placeholder,
    meta,
    options = [],
    ...props
}) => {
    const { touched = false, error = false, warning = false } = meta ?? {};
    const { t } = useTranslation();
    const [value, setValue] = useState(
        input.value !== '' ? options.find(({ value: optValue }) => optValue === input.value) : '',
    );

    const handleChange = (newValue) => {
        setValue(newValue === null ? '' : newValue);
        input.onChange(newValue?.value);
    };

    // this is to force a clean value on redux form reset as per the bug described here :
    // https://stackoverflow.com/questions/50640858/react-select-does-not-clear-value-when-redux-form-is-reset
    useEffect(() => {
        if (input.value === '') {
            setValue('');
        }
    }, [input.value]);

    return (
        <div
            className={`
                field
                field-date
                ${touched && error ? 'field--error' : ''}
                ${touched && warning ? 'field--warning' : ''}
                ${className}
            `}
        >
            <label className={labelHidden ? 'field__label--hidden' : ''} htmlFor={input.name}>{label}</label>
            <Select
                {...props}
                className="select-container"
                classNamePrefix="select"
                isClearable={isClearable}
                isSearchable={isSearchable}
                name={input.name}
                onChange={handleChange}
                options={hasAllOption ? [{ value: '', label: t('select.all') }, ...options] : options}
                placeholder={placeholder ?? ''}
                value={value}
            />
            {touched
                && ((error && <span>{t(error)}</span>)
                    // eslint-disable-next-line react/no-danger
                    || (warning && <span dangerouslySetInnerHTML={{ __html: t(warning) }} />))}
        </div>
    );
};

SelectField.propTypes = {
    ...fieldPropTypes(PropTypes.string),
    hasAllOption: PropTypes.bool,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    })).isRequired,
};

export default SelectField;
