import PropTypes from 'prop-types';
import { find, pathEq } from 'ramda';
import { Trans, useTranslation } from 'react-i18next';
import SoftSkillPicto from '../common/SoftSkillPicto';
import Colored from 'components/pdf/common/Colored';

import './style.scss';

const PageResults = ({ softSkillResults: { detailedSituations, softSkillScores } }) => {
    const { t } = useTranslation();

    const softSkillsTop3 = softSkillScores
        .filter(({ isTop3 }) => isTop3)
        .map(({ score, softSkill }) => ({
            id: softSkill.id,
            key: softSkill.key,
            score,
            situation: find(
                pathEq(['situation', 'metaCapability', 'softSkillId'], softSkill.id),
            )(detailedSituations),
        }));

    return (
        <div className="pdf-result">
            <Colored color="grey" withPadding>
                <h2 className="pdf__title">{t('pdf.pageResults.title')}</h2>
                <p className="pdf__text--justify"><Trans i18nKey="pdf.pageResults.description" /></p>
            </Colored>
            <div className="pdf-result__content">
                <ol>
                    {softSkillsTop3.map((softSkill) => (
                        <li key={softSkill.key}>
                            <SoftSkillPicto softSkill={softSkill} withName={false} />
                            <div className="pdf-result__soft-skill">
                                <h4 className="color--pink">{t(`softSkill.${softSkill.key}`)}</h4>
                                <div className="pdf__text--justify">
                                    <p>
                                        {t(`softSkill.${softSkill.key}.definition.part1.short`)}
                                        <br />
                                        {
                                            // exercise 3 is optional so the situation can be undefined
                                            softSkill.situation?.details && (
                                                <span>
                                                    <Trans
                                                        i18nKey="pdf.pageResults.soft_skill_situation"
                                                        values={{ situation: softSkill.situation.details.job }}
                                                    />
                                                </span>
                                            )
                                        }
                                    </p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ol>
            </div>
            <div className="pdf-result__shape">
                <img
                    alt="Result shape"
                    src="/assets/pdf/pdf-result-shape.svg"
                />
            </div>
        </div>
    );
};

PageResults.propTypes = {
    softSkillResults: PropTypes.shape({
        detailedSituations: PropTypes.arrayOf(PropTypes.shape({
            details: PropTypes.shape({
                job: PropTypes.string.isRequired,
            }).isRequired,
            situation: PropTypes.shape({
                metaCapability: PropTypes.shape({
                    softSkillId: PropTypes.string.isRequired,
                }),
            }).isRequired,
        })).isRequired,
        softSkillScores: PropTypes.arrayOf(PropTypes.shape({
            softSkill: PropTypes.shape({
                id: PropTypes.string.isRequired,
                key: PropTypes.string.isRequired,
            }).isRequired,
        })).isRequired,
    }).isRequired,
};

export default PageResults;
