import classnames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

const MainWithCard = ({ children, header, title, withSmallCard = false, withoutPadding = false }) => (
    <>
        <div className="main-card__header">{header}</div>
        {title && (
            <h1 className="title main-card__title">{title}</h1>
        )}
        <div className={
            classnames(
                'main-card',
                { 'main-card--small': withSmallCard, 'main-card--no-padding': withoutPadding },
            )
        }
        >
            {children}
        </div>
    </>
);

MainWithCard.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.node,
    title: PropTypes.string,
    withoutPadding: PropTypes.bool,
    withSmallCard: PropTypes.bool,
};

export default MainWithCard;
