import { useContext, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { markStepComplete, saveSelectedAchievements } from 'actions';
import Button from 'components/common/Button';
import Container from 'components/layout/Container';
import MainWithSteps from 'components/layout/MainWithSteps';
import Panel from 'components/layout/Panel';
import FormAchievements from 'components/Step1/Part1/FormAchievements';
import DescriptionExercise from 'components/StepDescription/DescriptionExercise';
import Instructions from 'components/StepDescription/Instructions';
import StepList from 'components/StepDescription/StepList';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import LanguageContext from 'Language/LanguageContext';
import WithCurrentForm from 'providers/WithCurrentForm';
import { ROUTES } from 'utils/constants';

const PartOne = () => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const push = useLocalizedHistoryPush();
    const dispatch = useDispatch();
    const { language } = useContext(LanguageContext);
    const { firstStepComplete, selectedAchievements } = useSelector((state) => state.currentForm);
    const isStepValid = selectedAchievements?.length >= 3;

    const showInstructions = () => {
        confirmAlert({
            // TODO PCD: Refacto to delete eslint-disable
            // eslint-disable-next-line react/no-unstable-nested-components
            customUI: ({ onClose }) => (
                <Instructions onClose={onClose}>
                    <DescriptionExercise step={1} />
                </Instructions>
            ),
        });
    };

    useEffect(() => {
        showInstructions();
    }, []);

    if (firstStepComplete) {
        return <Redirect to="/account" />;
    }

    const onValidate = async () => {
        if (!isStepValid) {
            return;
        }
        await dispatch(saveSelectedAchievements(API_URL, selectedAchievements
            .map((selectedAchievement) => selectedAchievement.achievementId)));

        // if only 3 achievements are selected, save them as step 3 and go straight to the end of step1
        if (selectedAchievements.length === 3) {
            await dispatch(markStepComplete(API_URL, { step: 1 }, language));
            push(ROUTES.step1.ending);
        } else {
            push(ROUTES.step1.part2);
        }
    };

    return (
        <Container>
            <Panel>
                <StepList step={1} />
            </Panel>
            <MainWithSteps
                currentStep={1}
                footer={(
                    <Button
                        classes="button--block-mobile button--black"
                        disabled={!isStepValid}
                        onClick={onValidate}
                        text={t('common.validate')}
                    />
                )}
                textContext={t('step1.part1.title.text1')}
                title={t('step1.part1.title.text2')}
            >
                <FormAchievements />
            </MainWithSteps>
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm><PartOne {...props} /></WithCurrentForm>
);
