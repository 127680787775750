import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getSelectedAchievements, setSelectedAchievementTop3Value } from 'actions';
import SelectedAchievementCard from 'components/Step1/Part2/SelectedAchievementCard';
import { useAppData } from 'hooks/useAppData';
import './style.scss';

const FormTop3Achievements = () => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const dispatch = useDispatch();
    const { selectedAchievements } = useSelector((state) => state.currentForm);

    useEffect(() => {
        dispatch(getSelectedAchievements(API_URL));
    }, []);

    if (selectedAchievements.length === 0) {
        return null;
    }

    if (selectedAchievements.length < 3) {
        return <Redirect to="/account" />;
    }

    const onSelectedAchievementClick = (selectedAchievement) => (value) => {
        dispatch(setSelectedAchievementTop3Value({ id: selectedAchievement.id, value }));
    };

    return (
        <div className="step1-part2__grid with-side-padding">
            {selectedAchievements.map((selectedAchievement) => (
                <SelectedAchievementCard
                    key={selectedAchievement.achievementId}
                    isSelected={selectedAchievement.isTop3 === true}
                    onClick={onSelectedAchievementClick(selectedAchievement)}
                    text={t(`achievement.${selectedAchievement.key}`)}
                />
            ))}
        </div>
    );
};

export default FormTop3Achievements;
