import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormSoftSkillsTop } from 'actions';
import Top3Image from 'components/Step2/Top3Image';
import { useAppData } from 'hooks/useAppData';
import './style.scss';

const ShareTop3Skills = ({ match: { params: { formId } } }) => {
    const { API_URL } = useAppData();
    const dispatch = useDispatch();
    const softSkillsTop3 = useSelector((state) => state.currentForm.softSkillsTop3);

    useEffect(() => {
        dispatch(getFormSoftSkillsTop(API_URL, formId));
    }, []);

    return (
        <div className="shareTop3__container">
            <Top3Image softSkillsTop3={softSkillsTop3} />
        </div>
    );
};

ShareTop3Skills.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            formId: PropTypes.string.isRequired,
        }),
    }),
};

export default ShareTop3Skills;
