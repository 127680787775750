import { ROUTES } from './constants';

const goToStep = (push, step) => {
    switch (step) {
        case 1:
            push(ROUTES.step1.part1);
            break;
        case 2:
            push(ROUTES.step2.main);
            break;
        case 3:
            push(ROUTES.step3.part1);
            break;
        case 4:
            push(ROUTES.form);
            break;
        case 5:
            push(ROUTES.results);
            break;
        default:
            push(ROUTES.account);
    }
};

export default goToStep;
