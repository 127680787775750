import PropTypes from 'prop-types';
import './style.scss';
import Spinner from '../common/Spinner';

const LoadingScreen = ({
    label,
}) => (
    <div className="loading-screen">
        <h1 className="title base-margin loading-screen__title">
            {label}
        </h1>
        <Spinner color="black" />
        <div className="loading-illustration">
            <img alt="" className="loading-illustration__image" src="/assets/loading.svg" aria-hidden />
            <img
                alt=""
                className="loading-illustration__vector"
                src="/assets/bg-vectors/bg-vector-loading.svg"
                aria-hidden
            />
        </div>
    </div>
);

LoadingScreen.propTypes = {
    label: PropTypes.string,
};

export default LoadingScreen;
