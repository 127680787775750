import PropTypes from 'prop-types';
import './style.scss';

const genderField = ({ input, id, label, type }) => (
    <label
        className={
            `gender__button gender__button--${input.value}
            ${input.checked ? 'gender__button--active' : null}`
        }
        htmlFor={id}
    >
        {label}
        <input {...input} id={id} type={type} />
    </label>
);

genderField.propTypes = {
    id: PropTypes.string.isRequired,
    input: PropTypes.shape({
        checked: PropTypes.bool,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    }),
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
};

export default genderField;
