import PropTypes from 'prop-types';
import './style.scss';

const HeaderMenu = ({
    className = '',
    leftElement,
    rightElement,
}) => (
    <div className={`header-menu__wrapper-menu ${className}`}>
        <div className="header-menu__inner">
            {leftElement}
            {rightElement}
        </div>
    </div>
);

HeaderMenu.propTypes = {
    className: PropTypes.string,
    leftElement: PropTypes.node,
    rightElement: PropTypes.node,
};

export default HeaderMenu;
