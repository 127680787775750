import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import './style.scss';

/**
 * Display instructions popup at the beginning of an exercise
 * @param {ReactNode} children content of the popup (above buttons)
 * @param {() => void} onClose default button, simply dismiss the popup
 * @param {() => void | undefined} onSkip (optional) display a "skip step" button
 */
const Instructions = ({ children, onClose, onSkip }) => {
    const { t } = useTranslation();
    const [skipping, setSkipping] = useState(false);

    return (
        <Modal closeModal={onClose} title={t('instruction.title')} fullscreen>
            <div className="base-margin">
                {children}
                {
                    onSkip && (
                        <p className="instructions__buttons__skippable">
                            {t('instruction.skippable')}
                        </p>
                    )
                }
                <div className="instructions__buttons">
                    {
                        onSkip && (
                            <Button
                                classes="base-margin button--grey button--block-mobile instructions__buttons__skip"
                                onClick={() => { setSkipping(true); onSkip(); }}
                                submitting={skipping}
                                text={t('instruction.skip')}
                                type="button"
                            />
                        )
                    }
                    <button
                        className="base-margin button button--black button--block"
                        onClick={onClose}
                        type="button"
                    >
                        {t('instruction.start')}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

Instructions.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    onSkip: PropTypes.func,
};

export default Instructions;
