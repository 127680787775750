import { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'components/common/Button';
import ShareResultsModal from 'components/ShareResultsModal';
import { useAppData } from 'hooks/useAppData';
import LanguageContext from 'Language/LanguageContext';

import './style.scss';

const ResultsPanel = () => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const { language } = useContext(LanguageContext);
    const { lastFormCompleteId: formId } = useSelector((state) => state.user);
    const shareUrl = `${API_URL}/share/${language}/${formId}`;

    const onShare = () => {
        confirmAlert({
            /* eslint-disable-next-line */
            customUI: ({ onClose }) => (
                <ShareResultsModal
                    onClose={onClose}
                    shareUrl={shareUrl}
                />
            ),
        });
    };

    const urlToPdf = `${API_URL}/pdf/download/${language}`;

    const onDownloadPdfClick = () => {
        window.open(urlToPdf, '_blank');
    };

    return (
        <div className="results-panel">
            <h2 className="panel-title">{t('results.description.title')}</h2>
            <span className="panel-content">{t('results.description.text')}</span>
            <Button
                classes="button--white panel__download-pdf-button"
                img="/assets/icons/download.svg"
                onClick={() => onDownloadPdfClick()}
                text={t('results.pdf.download')}
            >
                {t('results.pdf.download')}
            </Button>
            <Button
                classes="button--black panel__share-button"
                img="/assets/icons/share.svg"
                onClick={() => onShare()}
                text={t('results.share')}
            >
                {t('results.share')}
            </Button>
        </div>
    );
};

export default ResultsPanel;
