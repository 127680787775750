import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { COLORS } from 'utils/constants';
import getScoreStarLayout from 'utils/getScoreStarLayout';
import slugify from 'utils/slugify';
import './style.scss';

const Top3SoftSkills = ({ softSkillsTop3 }) => {
    const { t } = useTranslation();

    return (
        <ul className="top-3-soft-skills">
            {
                softSkillsTop3.map(({ score, softSkill: { key } }) => (
                    <li key={key}>
                        <Link to={`/results/soft-skill/${slugify(key)}`}>
                            <img alt="" src={`/assets/softSkills/V1/${key}.svg`} />
                            <div className="top-3-soft-skills__text-container">
                                <span>{t(`softSkill.${key}`)}</span>
                                <div className="top-3-soft-skills__score-container">
                                    {getScoreStarLayout({ score: Math.floor(score / 2), color: COLORS.black })}
                                </div>
                            </div>
                        </Link>
                    </li>
                ))
            }
        </ul>
    );
};

Top3SoftSkills.propTypes = {
    softSkillsTop3: PropTypes.arrayOf(PropTypes.shape({
        score: PropTypes.number.isRequired,
        softSkill: PropTypes.shape({
            key: PropTypes.string.isRequired,
        }).isRequired,
    })).isRequired,
};

export default Top3SoftSkills;
