import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import SimpleField from 'components/common/form/SimpleField';
import { required } from 'utils/validators';

const PasswordResetForm = ({ error, handleSubmit, submitting, valid }) => {
    const { t } = useTranslation();

    return (
        <form className="base-margin" method="post" onSubmit={handleSubmit}>
            {error && <div className="errors">{error}</div>}
            <Field
                component={SimpleField}
                label={t('auth.newPassword.label')}
                name="password"
                placeholder={t('auth.newPassword.label')}
                type="password"
                validate={[required]}
                labelHidden
            />
            <Field
                component={SimpleField}
                label={t('auth.confirmationPassword.label')}
                name="confirmationPassword"
                placeholder={t('auth.confirmationPassword.label')}
                type="password"
                validate={[required]}
                labelHidden
            />
            <button
                className="button button--block button--blue base-margin"
                disabled={!valid || submitting}
                type="submit"
            >
                {t('auth.resetPassword.submit')}
            </button>
        </form>
    );
};

PasswordResetForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

export default withRouter(reduxForm({
    form: 'PasswordResetForm',
})(PasswordResetForm));
