import PropTypes from 'prop-types';
import { useState } from 'react';
import IconInfo from 'components/common/icons/IconInfo';
import Main from 'components/layout/Main';
import StepInformationModal from 'components/StepInformationModal';
import './style.scss';

export const SCROLL_ANCHOR_ID = 'scroll-anchor';

const MainWithSteps = ({
    children,
    currentStep,
    footer,
    iconColor,
    textContext,
    title,
    subtitle,
    isLarge,
    isFullWidth,
}) => {
    const [isModalOpened, setIsModalOpened] = useState(false);

    return (
        <Main className="main-steps__container" isFullWidth={isFullWidth} isLarge={isLarge}>
            {isModalOpened && currentStep && (
                <StepInformationModal onClose={() => setIsModalOpened(false)} step={currentStep} />
            )}
            <div className="main-steps">
                {/* div used as a ref to scroll to the top of the component */}
                <div id={SCROLL_ANCHOR_ID} />
                <div className="main-steps__header">
                    {textContext && (
                        <div className="main-steps__context">
                            <span>{textContext}</span>
                            {iconColor && currentStep && (
                                <button
                                    onClick={() => setIsModalOpened(true)}
                                    type="button"
                                >
                                    <IconInfo color={iconColor} />
                                </button>
                            )}
                        </div>
                    )}
                    <h1 className="title">{title}</h1>
                    {subtitle && <span className="main-steps__subtitle">{subtitle}</span>}
                </div>
                <div className="main-steps__content">{children}</div>
                {footer && <div>{footer}</div>}
            </div>
        </Main>
    );
};

MainWithSteps.propTypes = {
    children: PropTypes.node.isRequired,
    currentStep: PropTypes.number,
    footer: PropTypes.node,
    iconColor: PropTypes.string,
    isFullWidth: PropTypes.bool,
    isLarge: PropTypes.bool,
    subtitle: PropTypes.string,
    textContext: PropTypes.string,
    title: PropTypes.string,
};

export default MainWithSteps;
