import IconStar from 'components/common/icons/IconStar';

const getScoreStarLayout = ({ score, color }) => {
    const stars = [];
    // there is always at least 1 star
    for (let i = 0; i < Math.max(1, score); i++) {
        stars.push(<IconStar key={i} color={color} />);
    }

    return stars;
};

export default getScoreStarLayout;
