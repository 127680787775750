import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import SsoBanner from '../SsoBanner';
import Button from 'components/common/Button';
import SimpleField from 'components/common/form/SimpleField';
import { useAppData } from 'hooks/useAppData';
import { useUrlParams } from 'hooks/useUrlParams';
import { AUTH_CONSTANTS } from 'utils/constants';
import { emailFormat, required } from 'utils/validators';

const LoginForm = ({ error, handleSubmit, submitting, valid }) => {
    const { t } = useTranslation();
    const { AUTH_URL } = useAppData();
    const updateUrlParams = useUrlParams();

    return (
        <>
            <h1 className="modal__title">
                {t('auth.login.title')}
            </h1>
            <SsoBanner />
            <form className="base-margin" method="post" onSubmit={handleSubmit}>
                {error && <div className="error">{error}</div>}
                <Field
                    component={SimpleField}
                    label={t('auth.email.label')}
                    name="email"
                    type="email"
                    validate={[required, emailFormat]}
                />
                <Field
                    component={SimpleField}
                    label={t('auth.password.label')}
                    name="password"
                    type="password"
                    validate={[required]}
                />
                <button
                    className="link strong"
                    onClick={() => updateUrlParams({ open: AUTH_CONSTANTS.LOSTPASSWORD })}
                    type="button"
                >
                    {t('auth.login.forgotten_password')}
                </button>
                <div>
                    <Button
                        classes="button--block button--black base-margin"
                        disabled={!valid}
                        submitting={submitting}
                        text={t('auth.login.submit')}
                        submit
                    />
                    <a
                        href={`${AUTH_URL}/api/auth/hester/google`}
                    >
                        <Button
                            classes="button--block button--outline auth-form__google-button"
                            img="/assets/icons/google.svg"
                            text={t('auth.google')}
                        />
                    </a>
                </div>
                <div className="base-margin auth-form__redirect_link">
                    <span>{t('auth.login.noAccount')}</span>
                    <button
                        className="link strong"
                        onClick={() => updateUrlParams({ open: AUTH_CONSTANTS.SIGNUP })}
                        type="button"
                    >
                        {t('auth.login.switchToSignup')}
                    </button>
                </div>
            </form>
        </>
    );
};

LoginForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

export default reduxForm({
    form: 'loginForm',
})(LoginForm);
