/* eslint-disable max-len */
import PropTypes from 'prop-types';

const IconInfo = ({ color = 'black' }) => (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
            clipRule="evenodd"
            d="M12.4856 1.12584C12.1836 0.958052 11.8164 0.958052 11.5144 1.12584L2.51436 6.12584C2.19689 6.30221 2 6.63683 2 7V17C2 17.3632 2.19689 17.6978 2.51436 17.8742L11.5144 22.8742C11.8164 23.0419 12.1836 23.0419 12.4856 22.8742L21.4856 17.8742C21.8031 17.6978 22 17.3632 22 17V7C22 6.63683 21.8031 6.30221 21.4856 6.12584L12.4856 1.12584ZM4 16.4116V7.5884L12 3.14396L20 7.5884V16.4116L12 20.856L4 16.4116ZM11 10.5C10.4477 10.5 10 10.9477 10 11.5C10 12.0523 10.4477 12.5 11 12.5V14.5C10.4477 14.5 10 14.9477 10 15.5C10 16.0523 10.4477 16.5 11 16.5H12H13C13.5523 16.5 14 16.0523 14 15.5C14 14.9477 13.5523 14.5 13 14.5V11.5C13 10.9477 12.5523 10.5 12 10.5H11ZM13.5 8C13.5 8.82843 12.8284 9.5 12 9.5C11.1716 9.5 10.5 8.82843 10.5 8C10.5 7.17157 11.1716 6.5 12 6.5C12.8284 6.5 13.5 7.17157 13.5 8Z"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
);

IconInfo.propTypes = {
    color: PropTypes.string,
};

export default IconInfo;
