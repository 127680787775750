import { useTranslation } from 'react-i18next';

import './style.scss';

const BackPage = () => {
    const { t } = useTranslation();

    return (
        <div className="back-page__container">
            <img alt="Last page top shape" className="shape-top" src="/assets/pdf/back-top-shape.svg" />
            <div className="back-page__content">
                <img alt="Jobteaser logo" src="/assets/JT-black.svg" />
                <h1 className="back-page__title">{t('pdf.backPage.mainText')}</h1>
                <p className="back-page__subtitle">{t('pdf.backPage.subText')}</p>
            </div>
            <img alt="Last page bottom shape" className="shape-bottom" src="/assets/pdf/back-bottom-shape.svg" />
        </div>
    );
};

export default BackPage;
