import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.scss';

const SituationCardCheckbox = ({ index, situation, isSelected = false, onClick }) => {
    const { t } = useTranslation();

    return (
        <button
            className={classnames(
                'situation-card-checkbox',
                {
                    'situation-card-checkbox--primary': isSelected === true,
                },
            )}
            onClick={() => onClick(!isSelected)}
            type="button"
        >
            <img alt="checked" src={`/assets/icons/${isSelected ? 'check' : 'checkbox'}.svg`} />
            <div className="situation-card-checkbox__text">
                <span>{`${t('situation')} ${index.toString()}`}</span>
                <p>{t(`situation.${situation}`)}</p>
            </div>
        </button>
    );
};

SituationCardCheckbox.propTypes = {
    index: PropTypes.number.isRequired,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    situation: PropTypes.string.isRequired,
};

export default SituationCardCheckbox;
