import PropTypes from 'prop-types';
import './style.scss';

const Backdrop = ({ children, onClick, zIndex }) => (
    <div aria-hidden="true" className="backdrop" onClick={onClick} style={{ zIndex }}>
        {children}
    </div>
);

Backdrop.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    zIndex: PropTypes.number,
};

export default Backdrop;
