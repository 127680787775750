import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Button from 'components/common/Button';
import Tag from 'components/common/Tag';
import Container from 'components/layout/Container';
import MainWithSteps from 'components/layout/MainWithSteps';
import Panel from 'components/layout/Panel';
import StepList from 'components/StepDescription/StepList';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import WithCurrentForm from 'providers/WithCurrentForm';
import goToStep from 'utils/goToStep';
import './style.scss';

const Ending = () => {
    const { t } = useTranslation();
    const push = useLocalizedHistoryPush();
    const { firstStepComplete, secondStepComplete } = useSelector((state) => state.currentForm);

    if (!firstStepComplete || secondStepComplete) {
        return <Redirect to="/account" />;
    }

    return (
        <Container>
            <Panel>
                <StepList step={1} />
            </Panel>
            <MainWithSteps
                footer={(
                    <Button
                        classes="button--block-mobile button--black"
                        onClick={() => {
                            goToStep(push, 2);
                        }}
                        text={t('common.continue')}
                    />
                )}
                subtitle={t('step1.ending.text')}
                title={t('step1.ending.title')}
            >
                <div className="step-one-ending__success-wrapper">
                    <div className="step-one-ending__content">
                        <div className="step-one-ending__text-wrapper">
                            <Tag classes="tag--grey">{t('step1.ending.great.achievement')}</Tag>
                            <h2 className="step-one-ending__text">{t('step1.ending.success')}</h2>
                        </div>
                        <img alt="clap" className="step-one-ending__image" src="/assets/icons/clap.svg" />
                    </div>
                </div>
            </MainWithSteps>
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm><Ending {...props} /></WithCurrentForm>
);
