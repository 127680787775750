import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import MetaCapabilityScore from '../MetaCapabilityScore';

import './style.scss';

const MetaCapability = ({ metaCapability, index }) => {
    const { t } = useTranslation();

    return (
        <div className="meta-capability">
            <div className="meta-capability__header">
                <h3 className="pdf__title strong">
                    {t('pdf.pageSoftSkill.metaCapability.title', {
                        index,
                        metaCapability: t(`metaCapability.${metaCapability.key}`),
                    })}
                </h3>
                <MetaCapabilityScore score={metaCapability.score} />
            </div>
            <div className="pdf__text--justify">
                <p><Trans i18nKey={`metaCapability.${metaCapability.key}.definition`} /></p>
                <ul>
                    <li className="meta-capability__list-item">
                        <p>
                            <strong><Trans i18nKey="pdf.pageSoftSkill.metaCapability.your_level" /></strong>
                            &nbsp;
                            <Trans
                                i18nKey={
                                    `metaCapability.${metaCapability.key}.definition.level_${metaCapability.score}`
                                }
                            />
                        </p>
                    </li>
                    <li className="meta-capability__list-item">
                        <p>
                            <strong><Trans i18nKey="pdf.pageSoftSkill.metaCapability.go_further" /></strong>
                            &nbsp;
                            <Trans i18nKey={`metaCapability.${metaCapability.key}.how_to_develop`} />
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

MetaCapability.propTypes = {
    index: PropTypes.number.isRequired,
    metaCapability: PropTypes.shape({
        key: PropTypes.string.isRequired,
        score: PropTypes.number.isRequired,
    }).isRequired,
};

export default MetaCapability;
