import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import SoftSkillPicto from 'components/pdf/common/SoftSkillPicto';

import './style.scss';

// function to format a softSkill with {key, score}
const formatSoftSkillScoresByCategory = (softSkillScores, categoryKey) => (
    softSkillScores.reduce((acc, softSkillScore) => {
        const { softSkill } = softSkillScore;

        return softSkill.category.key === categoryKey
            ? [...acc, { ...softSkill, score: softSkillScore.score }]
            : acc;
    }, [])
);

const PageProfileDetails = ({ softSkillScores }) => {
    const { t } = useTranslation();

    // use a Set to remove duplicates
    const categories = [...new Set(softSkillScores
        .map((softSkillScore) => softSkillScore.softSkill.category.key)),
    ];

    return (
        <>
            <h2 className="pdf__title">{t('pdf.pageProfileDetails.title')}</h2>
            <div className="pdf__text--justify">
                <p><Trans i18nKey="pdf.pageProfileDetails.intro" /></p>
            </div>
            <div className="profile-details__categories">
                {categories.map((categoryKey) => (
                    <div key={categoryKey} className="profile-details__category">
                        <h3 className="pdf__title">{t(`category.${categoryKey}`)}</h3>
                        <div className="profile-details__soft-skills">
                            {formatSoftSkillScoresByCategory(softSkillScores, categoryKey).map((softSkill) => (
                                <SoftSkillPicto key={softSkill.key} softSkill={softSkill} />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

PageProfileDetails.propTypes = {
    softSkillScores: PropTypes.arrayOf(PropTypes.shape({
        score: PropTypes.number.isRequired,
        softSkill: PropTypes.shape({
            category: PropTypes.shape({
                key: PropTypes.string.isRequired,
            }),
            key: PropTypes.string.isRequired,
        }).isRequired,
    })).isRequired,
};

export default PageProfileDetails;
