export const generateJobSuggestions = (softSkills) => {
    const jobZoneLevels = ['12', '23', '45'];

    return jobZoneLevels.map((jobZoneLevel) => (
        {
            level: jobZoneLevel,
            suggestions: softSkills.map((softSkill) => (
                {
                    index: Math.round(Math.random()) + 1,
                    softSkill,
                }
            )),
        }
    ));
};

export default { generateJobSuggestions };
