import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'utils/constants';
import getScoreStarLayout from 'utils/getScoreStarLayout';
import './style.scss';

const MetaCapabilityScore = ({ score }) => {
    const { t } = useTranslation();

    return (
        <div className="meta-capability-score">
            <span>{t('pdf.metaCapability.score')}</span>
            <div className="meta-capability-score__stars">
                {getScoreStarLayout({ score, color: COLORS.primary })}
            </div>
        </div>
    );
};

MetaCapabilityScore.propTypes = {
    score: PropTypes.number.isRequired,
};

export default MetaCapabilityScore;
