import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { clearDetailedSituation, getDetailedSituation } from 'actions';
import Container from 'components/layout/Container';
import MainInner from 'components/layout/MainInner';
import MainWithBackButton from 'components/layout/MainWithBackButton';
import Panel from 'components/layout/Panel';
import ResultsPanel from 'components/ResultsPanel';
import { useAppData } from 'hooks/useAppData';

import './style.scss';

const convertSlugToSoftSkill = (value) => value
    .toUpperCase()
    .replace('-', '_');

const SoftSkillPage = ({ match }) => {
    const { softSkillSlug: softSkillKeySlugified } = match.params;
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const dispatch = useDispatch();
    const detailedSituation = useSelector((state) => state.result.detailedSituation, shallowEqual);

    useEffect(() => {
        dispatch(getDetailedSituation(API_URL, softSkillKeySlugified));

        return () => dispatch(clearDetailedSituation());
    }, []);

    if (detailedSituation === null) {
        // TODO : loading page
        return null;
    }

    const softSkill = detailedSituation?.situation?.metaCapability?.softSkill?.key
        || convertSlugToSoftSkill(softSkillKeySlugified);

    return (
        <Container bgColor="white">
            <Panel>
                <ResultsPanel />
            </Panel>
            <MainWithBackButton className="soft-skill-page__main" to="/results" isLarge>
                <MainInner className="soft-skill-page__main-inner">
                    <div className="soft-skill-page__skill">
                        <img alt="" src={`/assets/softSkills/V1/${softSkill}.svg`} />
                        <h2>{t(`softSkill.${softSkill}`)}</h2>
                        <span>{t(`softSkill.${softSkill}.definition.part1.short`)}</span>
                    </div>
                    {
                        detailedSituation?.details && (
                            <>
                                <div className="soft-skill-page__situation__wrapper">
                                    <p
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={
                                            {
                                                __html:
                                                    `${t('softSkillPage.text1')} ${t(`softSkill.${softSkill}`)
                                                        .toLowerCase()} :`,
                                            }
                                        }
                                    />
                                    <strong>{t(`situation.${detailedSituation.situation.key}`)}</strong>
                                </div>
                                <div className="soft-skill-page__experience">
                                    <img alt="" className="starting-quote" src="/assets/icons/starting-quote.svg" />
                                    <div className="content">
                                        <h4>{`${t('softSkillPage.text2')} ${detailedSituation.details.job}`}</h4>
                                        <p>{detailedSituation.details.tasks}</p>
                                        <p>{detailedSituation.details.actions}</p>
                                        <p>{detailedSituation.details.results}</p>
                                    </div>
                                    <img alt="" className="ending-quote" src="/assets/icons/ending-quote.svg" />
                                </div>
                            </>
                        )
                    }
                </MainInner>
            </MainWithBackButton>
        </Container>
    );
};

SoftSkillPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            softSkillSlug: PropTypes.string.isRequired,
        }),
    }),
};

export default SoftSkillPage;
