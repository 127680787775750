import PropTypes from 'prop-types';
import './style.scss';

const ButtonIcon = ({
    className = '',
    children = [],
    disabled = false,
    onClick,
}) => (
    <button
        className={`button-icon ${className}`}
        disabled={disabled}
        onClick={onClick}
        type="button"
    >
        {children}
    </button>
);

ButtonIcon.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

export default ButtonIcon;
