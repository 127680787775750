import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import IconCheck from 'components/common/icons/IconCheck';
import IconClose from 'components/common/icons/IconClose';
import Tag from 'components/common/Tag';
import './style.scss';

const SituationCard = ({ number, onAnswer, text, value }) => {
    const { t } = useTranslation();

    return (
        <div className="situation-card__container">
            <div className="situation-card__content">
                <Tag classes="tag--grey">{`${t('situation')} ${number.toString()}`}</Tag>
                <p className="situation-card__description">{text}</p>
            </div>
            <div className="situation-card__buttons-wrapper">
                <Button
                    classes={classnames(
                        'situation-card__button',
                        {
                            'button--primary': value !== true,
                            'button--grey': value === true,
                        },
                    )}
                    imgComponent={<IconClose color={value !== true ? 'white' : undefined} height={10} />}
                    onClick={() => onAnswer(false)}
                    text={t('situation.no')}
                />
                <Button
                    classes={classnames(
                        'situation-card__button',
                        {
                            'button--black': value !== false,
                            'button--grey': value === false,
                        },
                    )}
                    imgComponent={<IconCheck color={value !== false ? 'white' : undefined} height={15} />}
                    onClick={() => onAnswer(true)}
                    text={t('situation.yes')}
                />
            </div>
        </div>
    );
};

SituationCard.propTypes = {
    number: PropTypes.number.isRequired,
    onAnswer: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.bool,
};

export default SituationCard;
