import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteChangeTracker = () => {
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname + location.search;

        if (window.jtTracker) {
            window.jtTracker.page(path);
        }
    }, [location]);

    return <div />;
};

export default RouteChangeTracker;
