import { useContext, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getFormResults, redoStep3 } from 'actions';
import Button from 'components/common/Button';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import MainInner from 'components/layout/MainInner';
import Panel from 'components/layout/Panel';
import ResultsPanel from 'components/ResultsPanel';
import ShareResultsModal from 'components/ShareResultsModal';
import Top3Achievements from 'components/Top3Achievements';
import Top3SoftSkills from 'components/Top3SoftSkills';
import { useAppData } from 'hooks/useAppData';
import LanguageContext from 'Language/LanguageContext';
import { ROUTES } from 'utils/constants';
import './style.scss';

const Results = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { language } = useContext(LanguageContext);
    const { email, rotiCompleted, lastFormCompleteId: formId, lastFormId } = useSelector((state) => state.user);
    const { API_URL } = useAppData();
    const top3Achievements = useSelector(({ result }) => result.selectedAchievements.filter(({ isTop3 }) => isTop3));
    const { softSkillsTop3, canRedoStep3 } = useSelector((state) => state.result);
    const shareUrl = `${API_URL}/share/${language}/${formId}`;

    useEffect(() => {
        dispatch(getFormResults(API_URL));
    }, []);

    // ensures that the user has already completed the roti
    if (!rotiCompleted) {
        return <Redirect to={ROUTES.form} />;
    }

    // ensures that the user has already finished a form
    if (!formId) {
        if (lastFormId) {
            return <Redirect to={ROUTES.step3.part1} />;
        }

        return <Redirect to={ROUTES.account} />;
    }

    if (!top3Achievements.length || !softSkillsTop3.length) {
        return null;
    }

    const onShare = () => {
        confirmAlert({
            /* eslint-disable-next-line */
            customUI: ({ onClose }) => (
                <ShareResultsModal
                    onClose={onClose}
                    shareUrl={shareUrl}
                />
            ),
        });
    };

    const urlToPdf = `${API_URL}/pdf/download/${language}`;

    const onDownloadPdfClick = () => {
        window.open(urlToPdf, '_blank');
    };

    const onGoBackToStep3 = () => {
        dispatch(redoStep3(API_URL));
    };

    return (
        <Container bgColor="white" className="results__container">
            <Panel>
                <ResultsPanel />
            </Panel>
            <Main className="results__main" isLarge>
                <MainInner isLarge>
                    <div className="results__title">
                        <h1>{t('results.title')}</h1>
                        <h2>{t('results.subtitle')}</h2>
                    </div>
                    <Top3SoftSkills softSkillsTop3={softSkillsTop3} />
                    <div className="results__buttons-container">
                        <Button
                            classes="button--black results__share-button"
                            onClick={() => onShare()}
                            text={t('results.share')}
                        >
                            {t('results.share')}
                        </Button>
                        <Button
                            classes="button--black"
                            onClick={() => onDownloadPdfClick()}
                            text={t('results.pdf.download')}
                        >
                            {t('results.pdf.download')}
                        </Button>
                    </div>
                    <div className="results__pdf">
                        <h2>{t('results.pdf.title')}</h2>
                        <span>{t('results.pdf.subtitle')}</span>
                        <div className="results__pdf-email">
                            {email}
                        </div>
                        <span className="results__pdf-infos">{t('results.pdf.infos')}</span>
                        <ul>
                            <li>{t('results.pdf.infos.top3.soft_skill')}</li>
                            <li>{t('results.pdf.infos.profil')}</li>
                            <li>{t('results.pdf.infos.profil.detail')}</li>
                            <li>{t('results.pdf.infos.top3.goals')}</li>
                        </ul>
                        <span className="results__pdf-others">{t('results.pdf.infos.others')}</span>
                    </div>
                    <div className="results__top3-goals">
                        <h2>{t('results.top3.goals.title')}</h2>
                        <span>{t('results.top3.goals.subtitle')}</span>
                        <Top3Achievements achievements={top3Achievements} />
                    </div>
                    {canRedoStep3 && (
                        <div className="results__back-to-step3">
                            <h2 className="title title--submedium">{t('results.skipStep3.title')}</h2>
                            <p className="text--medium">{t('results.skipStep3.content')}</p>
                            <Button
                                classes="button--black base-margin"
                                onClick={onGoBackToStep3}
                                text={t('account.action.new')}
                            />
                        </div>
                    )}
                </MainInner>
            </Main>
        </Container>
    );
};

export default Results;
