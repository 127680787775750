import PropTypes from 'prop-types';
import './style.scss';

const Spinner = ({ className = '', color }) => (
    <div className={`lds-ellipsis ${color ? `lds-ellipsis--${color}` : ''} ${className}`}>
        <div />
        <div />
        <div />
        <div />
    </div>
);

Spinner.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

export default Spinner;
