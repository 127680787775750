import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tag from 'components/common/Tag';
import './style.scss';

const SituationCard = ({ index, situation }) => {
    const { t } = useTranslation();

    return (
        <div className={classnames('situation-card')}>
            <Tag classes="tag--grey">{`${t('situation')} ${index.toString()}`}</Tag>
            <p>{t(`situation.${situation}`)}</p>
        </div>
    );
};

SituationCard.propTypes = {
    index: PropTypes.number.isRequired,
    situation: PropTypes.string.isRequired,
};

export default SituationCard;
