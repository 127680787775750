import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import BackPage from 'components/pdf/BackPage';
import FrontPage from 'components/pdf/FrontPage';
import PageAchievements from 'components/pdf/PageAchievements';
import PageHesterModel from 'components/pdf/PageHesterModel';
import PageInterviewAdvices from 'components/pdf/PageInterviewAdvices';
import PageInterviewMemo from 'components/pdf/PageInterviewMemo';
import PageIntro1 from 'components/pdf/PageIntro1';
import PageIntro2 from 'components/pdf/PageIntro2';
import PageJobSuggestions from 'components/pdf/PageJobSuggestions';
import PageLayout from 'components/pdf/PageLayout';
import PageProfileDetails from 'components/pdf/PageProfileDetails';
import PageProfileExplanation from 'components/pdf/PageProfileExplanation';
import PageResults from 'components/pdf/PageResults';
import PageResume from 'components/pdf/PageResume';
import PageSoftSkill from 'components/pdf/PageSoftSkill';
import { useAppData } from 'hooks/useAppData';
import formatDate from 'utils/formatDate';
import { GET } from 'utils/http';
import './style.scss';

const Restitution = ({ location: { search }, match: { params: { formId } } }) => {
    const { API_URL } = useAppData();
    const [loading, setLoading] = useState(true);
    const { security } = queryString.parse(search);
    const [data, setData] = useState({});

    const date = formatDate();

    useEffect(async () => {
        const response = await GET(`${API_URL}/pdf/data/${formId}?security=${security}`);

        setData(response);
        setLoading(false);
    }, []);

    if (loading) {
        return null;
    }

    let pageIndex = 1;
    const getNextPageIndex = (step = 1) => {
        pageIndex += step;

        return pageIndex;
    };

    const softSkills = data.softSkillsPagesData.softSkillScores.map((softSkillScore) => ({
        isTop3: softSkillScore.isTop3,
        key: softSkillScore.softSkill.key,
        score: softSkillScore.score,
        categoryKey: softSkillScore.softSkill.category.key,
        metaCapabilities: data.softSkillsPagesData.metaCapabilityScores
            .filter(({ metaCapability }) => metaCapability.softSkillId === softSkillScore.softSkill.id)
            .map((metaCapabilityScore) => ({
                key: metaCapabilityScore.metaCapability.key,
                score: Math.ceil(metaCapabilityScore.score / 2),
            })),
        experiencedSituations: data.softSkillsPagesData.experiencedSituations
            .filter(({ situation }) => situation.metaCapability.softSkillId === softSkillScore.softSkill.id)
            .map(({ situation }) => ({
                id: situation.id,
                key: situation.key,
                level: situation.level,
                metaCapability: {
                    id: situation.metaCapability.id,
                },
            })),
        detailedSituation: data.softSkillsPagesData.detailedSituations
            .find(({ situation }) => situation.metaCapability.softSkillId === softSkillScore.softSkill.id),
    }));

    const softSkillsPaginationStep = softSkills.reduce((total, { isTop3, metaCapabilities }) => {
        if (isTop3) {
            return total + 2 + Math.ceil(metaCapabilities.length / 2);
        }

        return total + 3;
    }, 0);

    let softSkillsPageIndex = 7;
    const getNextSoftSkillPageIndex = () => {
        softSkillsPageIndex += 1;

        return softSkillsPageIndex;
    };

    return (
        <div className="pdfLayout">
            <FrontPage date={date} user={data.user} />
            <PageLayout createdAt={date} page={getNextPageIndex()} user={data.user}>
                <PageIntro1 />
            </PageLayout>
            <PageLayout createdAt={date} page={getNextPageIndex()} user={data.user}>
                <PageIntro2 />
            </PageLayout>
            <PageLayout createdAt={date} page={getNextPageIndex()} user={data.user} withoutPadding>
                <PageResults softSkillResults={data.softSkillResults} />
            </PageLayout>
            <PageLayout createdAt={date} page={getNextPageIndex()} user={data.user}>
                <PageProfileDetails softSkillScores={data.softSkillResults.softSkillScores} />
            </PageLayout>
            <PageLayout createdAt={date} page={getNextPageIndex()} user={data.user}>
                <PageProfileExplanation />
            </PageLayout>
            {softSkills.map((softSkill, index) => (
                <PageSoftSkill
                    key={softSkill.key}
                    createdAt={date}
                    pageIndexGetter={getNextSoftSkillPageIndex}
                    softSkill={softSkill}
                    softSkillIndex={index + 1}
                    user={data.user}
                />
            ))}
            <PageLayout createdAt={date} page={getNextPageIndex(softSkillsPaginationStep + 1)} user={data.user}>
                <PageAchievements top3Achievements={data.top3Achievements} />
            </PageLayout>
            <PageInterviewMemo
                createdAt={date}
                firstPageIndex={getNextPageIndex()}
                softSkills={softSkills}
                user={data.user}
            />
            <PageLayout createdAt={date} page={getNextPageIndex(2)} user={data.user}>
                <PageInterviewAdvices />
            </PageLayout>
            <PageLayout createdAt={date} page={getNextPageIndex()} user={data.user}>
                <PageJobSuggestions softSkillResults={data.softSkillResults} />
            </PageLayout>
            <PageLayout createdAt={date} page={getNextPageIndex()} user={data.user}>
                <PageResume softSkillResults={data.softSkillResults} />
            </PageLayout>
            <PageLayout createdAt={date} page={getNextPageIndex()} user={data.user}>
                <PageHesterModel />
            </PageLayout>
            <PageLayout createdAt={date} page={getNextPageIndex()} user={data.user} backpage withoutPadding>
                <BackPage />
            </PageLayout>
        </div>
    );
};

Restitution.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            formId: PropTypes.string.isRequired,
        }),
    }),
};

export default Restitution;
