import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './style.scss';

const DescriptionAdmin = () => {
    const { t } = useTranslation();
    const { surname } = useSelector(({ user }) => user);

    return (
        <div className="description-admin">
            <h3 className="title title--small">{t('menu.title', { surname })}</h3>
            <img alt="waiting" src="/assets/waiting.svg" />
        </div>
    );
};

export default DescriptionAdmin;
