import classnames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

const AchievementCard = ({ isSelected, onClick, text }) => (
    <button
        className={classnames(
            'achievement-card__container',
            {
                'achievement-card--primary': isSelected === true,
            },
        )}
        onClick={() => onClick(!isSelected)}
        type="button"
    >
        <img alt="checked" src={`/assets/icons/${isSelected ? 'check' : 'checkbox'}.svg`} />
        <span>
            {text}
        </span>
    </button>
);

AchievementCard.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
};

export default AchievementCard;
