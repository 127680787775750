import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, reset } from 'redux-form';
import Button from 'components/common/Button';
import DateField from 'components/common/form/DateField';
import SelectField from 'components/common/form/SelectField';
import SimpleField from 'components/common/form/SimpleField';
import TextAreaField from 'components/common/form/TextAreaField';
import environmentsData from 'data/environments.json';
import { required } from 'utils/validators';
import './style.scss';

const StepThreeForm = ({
    handleSubmit,
    submitting,
    valid,
    onSkip,
}) => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [skipping, setSkipping] = useState(false);

    const onSubmit = (values) => {
        setStartDate(null);
        setEndDate(null);
        handleSubmit(values);
    };

    return (
        <form className="step-3__wrapper" method="post" onSubmit={onSubmit}>
            <Field
                component={SelectField}
                label={t('step3.question.environment.label')}
                name="environment"
                options={environmentsData.ENVIRONMENTS.map((env) => ({ label: t(env.value), value: env.id }))}
                placeholder={t('step3.question.environment.placeholder')}
                type="text"
                validate={[required]}
            />
            <Field
                component={SimpleField}
                label={t('step3.question.job.label')}
                maxLength={30}
                name="job"
                placeholder={t('step3.question.job.placeholder')}
                type="text"
                validate={[required]}
            />
            <div className="step-3__dates">
                <Field
                    component={DateField}
                    label={t('step3.question.startDate.label')}
                    max={endDate ? endDate.target.value : ''}
                    name="startDate"
                    onChange={(value) => setStartDate(value)}
                    validate={[required]}
                />
                <Field
                    component={DateField}
                    label={t('step3.question.endDate.label')}
                    min={startDate ? startDate.target.value : ''}
                    name="endDate"
                    onChange={(value) => setEndDate(value)}
                    validate={[required]}
                />
            </div>
            <Field
                component={TextAreaField}
                label={t('step3.question.tasks.label')}
                maxLength={300}
                name="tasks"
                placeholder={t('step3.question.placeholder')}
                validate={[required]}
            />
            <Field
                component={TextAreaField}
                label={t('step3.question.actions.label')}
                maxLength={300}
                name="actions"
                placeholder={t('step3.question.placeholder')}
                validate={[required]}
            />
            <Field
                component={TextAreaField}
                label={t('step3.question.results.label')}
                maxLength={300}
                name="results"
                placeholder={t('step3.question.placeholder')}
                validate={[required]}
            />
            <div className="step-3__footer base-margin">
                <Button
                    classes="button--block-mobile button--grey"
                    onClick={() => {
                        setSkipping(true);
                        onSkip();
                    }}
                    submitting={skipping}
                    text={t('instruction.skip')}
                />
                <Button
                    classes="button--block-mobile button--black"
                    disabled={!valid}
                    submitting={submitting}
                    text={t('common.continue')}
                    submit
                />
            </div>
        </form>
    );
};

StepThreeForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

export default reduxForm({
    form: 'stepThreeForm',
    onSubmitSuccess: (_, dispatch) => dispatch(reset('stepThreeForm')),
})(StepThreeForm);
