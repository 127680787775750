import PropTypes from 'prop-types';
import './style.scss';

const Navigation = ({ current, disableBack, disableNext, onBack, onNext, total }) => (
    <div className="nav">
        <button
            className={`nav__button ${disableBack ? 'nav__button--disabled' : ''}`}
            disabled={disableBack}
            onClick={onBack}
            type="button"
        >
            <img alt="previous" src="/assets/icons/back.svg" />
        </button>
        <div className="question">
            <span className="question__current">{current}</span>
            {total && (
                <span> &#8239;/&#8239;{total}</span>
            )}
        </div>
        <button
            className={`nav__button ${disableNext ? 'nav__button--disabled' : ''}`}
            disabled={disableNext}
            onClick={onNext}
            type="button"
        >
            <img alt="next" src="/assets/icons/next.svg" />
        </button>
    </div>
);

Navigation.propTypes = {
    current: PropTypes.number.isRequired,
    disableBack: PropTypes.bool,
    disableNext: PropTypes.bool,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    total: PropTypes.number,
};

export default Navigation;
