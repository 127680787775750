import { Trans, useTranslation } from 'react-i18next';
import { COLORS } from 'utils/constants';
import getScoreStarLayout from 'utils/getScoreStarLayout';
import './style.scss';

const PageProfileExplanation = () => {
    const { t } = useTranslation();

    const scores = [1, 2, 3];

    return (
        <>
            <h2 className="pdf__title">{t('pdf.pageProfileExplanation.title')}</h2>
            <h4 className="pdf__title"><Trans i18nKey="pdf.pageProfileExplanation.intro" /></h4>
            <ul className="pdf__text--justify">
                {scores.map((score) => (
                    <li key={score} className="score__item">
                        <div className="score__stars">
                            {getScoreStarLayout({ score, color: COLORS.primary })}
                        </div>
                        <p>
                            <span className="score__title color--pink strong">
                                {t(`pdf.pageProfileExplanation.score${score}.title`)}
                            </span>
                            &nbsp;
                            <span>{t(`pdf.pageProfileExplanation.score${score}.description`)}</span>
                        </p>
                    </li>
                ))}
            </ul>
            <div className="pdf__text--justify">
                <p><Trans i18nKey="pdf.pageProfileExplanation.conclusion" /></p>
                <p><Trans i18nKey="pdf.pageProfileExplanation.next" /></p>
            </div>
        </>
    );
};

export default PageProfileExplanation;
