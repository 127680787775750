import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.scss';
import '../input.scss';
import fieldPropTypes from 'utils/fieldPropTypes';

const DateField = ({
    className = '',
    input,
    label,
    labelHidden = false,
    min,
    max,
    placeholder,
    meta: { touched, error, warning },
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <div
            className={`
                field
                field-date
                ${touched && error ? 'field--error' : ''}
                ${touched && warning ? 'field--warning' : ''}
                ${className}
            `}
        >
            <label className={labelHidden ? 'field__label--hidden' : ''} htmlFor={input.name}>{label}</label>
            <input
                {...input}
                {...props}
                id={`field-${input.name}`}
                max={max}
                min={min}
                placeholder={placeholder}
                type="date"
            />
            {touched
                && ((error && <span>{t(error)}</span>)
                    // eslint-disable-next-line react/no-danger
                    || (warning && <span dangerouslySetInnerHTML={{ __html: t(warning) }} />))}
        </div>
    );
};

DateField.propTypes = {
    // small trick because redux form initialize the value with an empty string
    ...fieldPropTypes(PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]).isRequired),
    max: PropTypes.string,
    min: PropTypes.string,
};

export default DateField;
