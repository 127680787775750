import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'utils/constants';
import getScoreStarLayout from 'utils/getScoreStarLayout';

import './style.scss';

const SoftSkillPicto = ({ softSkill, withName = true }) => {
    const { t } = useTranslation();

    return (
        <div className="soft-skill-picto">
            <img
                alt=""
                aria-hidden="true"
                className="soft-skill-picto__picto"
                src={`/assets/pdf/softSkills/${softSkill.key}.svg`}
            />
            {withName && <span>{t(`softSkill.${softSkill.key}`)}</span>}
            {softSkill.score && (
                <div className="softSkill-score__container">
                    {getScoreStarLayout({ score: Math.floor(softSkill.score / 2), color: COLORS.primary })}
                </div>
            )}
        </div>
    );
};

SoftSkillPicto.propTypes = {
    softSkill: PropTypes.shape({
        key: PropTypes.string.isRequired,
        score: PropTypes.number,
    }).isRequired,
    withName: PropTypes.bool,
};

export default SoftSkillPicto;
