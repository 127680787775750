import PropTypes from 'prop-types';
import './style.scss';

const Description = ({ title, subtitle }) => (
    <div className="description">
        <h3 className="title title--small">{title}</h3>
        <p>{subtitle}</p>
        <img alt="hester" src="/assets/hester_illustration.svg" />
    </div>
);

Description.propTypes = {
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default Description;
