/* eslint-disable import/no-import-module-exports */
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import deepForceUpdate from 'react-deep-force-update';
import { render } from 'react-dom';
import App from './App';

const container = document.getElementById('app');

const appData = JSON.parse(container.dataset.app);

const sentryData = {
    dsn: appData.SENTRY_DSN_APP,
    environment: appData.SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
};

Sentry.init(sentryData);

let appInstance = null;

const renderApp = () => {
    try {
        let appElement = <App appData={appData} />;

        if (process.env.NODE_ENV !== 'development') {
            appElement = (
                <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>
                    {appElement}
                </Sentry.ErrorBoundary>
            );
        }

        appInstance = render(appElement, container);
    } catch (error) {
        if ('development' === process.env.NODE_ENV) {
            throw error;
        }
        console.error(error);
    }
};

renderApp();

// Enable Hot Module Replacement (HMR)
if (module.hot) {
    module.hot.accept('./App', () => {
        if (appInstance && appInstance.updater.isMounted(appInstance)) {
            // Force-update the whole tree, including components that refuse to update
            deepForceUpdate(appInstance);
        }

        renderApp();
    });
}
