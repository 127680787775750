import { sortBy } from 'ramda';
import {
    CLEAN_FORM,
    CLEAN_SELECTED_ACHIEVEMENTS,
    SET_ACHIEVEMENTS,
    SET_FORM,
    SET_FORM_STATUS,
    SET_SELECTED_ACHIEVEMENT_TOP3_VALUE,
    SET_SELECTED_ACHIEVEMENT,
    SET_SOFT_SKILLS_TOP3,
    UPDATE_EXPERIENCED_SITUATION,
    UPDATE_SELECTED_ACHIEVEMENTS,
    SET_SITUATION_CHOICES,
    SET_DETAILED_SITUATIONS,
} from 'actions/currentForm';

// id null allows us to know if the form has been set or not
const initialState = {
    id: null,
    achievements: [],
    detailedSituations: null,
    selectedAchievements: null,
    situationChoices: null,
    softSkillsTop3: [],
    formStatus: null,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case SET_FORM:
            return {
                ...initialState,
                ...payload.form,
                experiencedSituations: sortBy((situation) => situation.order)(payload.form.experiencedSituations),
            };
        case SET_FORM_STATUS:
            return {
                ...state,
                formStatus: payload.formStatus,
            };
        case UPDATE_EXPERIENCED_SITUATION: {
            const updatedExperiencedSituations = [...state.experiencedSituations];
            const index = updatedExperiencedSituations.findIndex(({ id }) => id === payload.experiencedSituation.id);
            updatedExperiencedSituations[index] = payload.experiencedSituation;

            return {
                ...state,
                experiencedSituations: updatedExperiencedSituations,
            };
        }
        case SET_ACHIEVEMENTS:
            return {
                ...state,
                achievements: payload.achievements,
            };
        case SET_SELECTED_ACHIEVEMENT: {
            const updatedSelectedAchievements = [...state.selectedAchievements];
            const index = updatedSelectedAchievements
                .findIndex(({ achievementId }) => achievementId === payload.achievement.id);
            if (index < 0 && payload.value === true) {
                updatedSelectedAchievements.push(
                    {
                        ...payload.achievement,
                        achievementId: payload.achievement.id,
                        id: null,
                    },
                );
            } else if (index >= 0 && payload.value === false) {
                updatedSelectedAchievements.splice(index, 1);
            }

            return {
                ...state,
                selectedAchievements: updatedSelectedAchievements,
            };
        }
        case UPDATE_SELECTED_ACHIEVEMENTS:
            return {
                ...state,
                selectedAchievements: payload.selectedAchievements,
            };
        case SET_SELECTED_ACHIEVEMENT_TOP3_VALUE: {
            const updatedSelectedAchievements = [...state.selectedAchievements];
            const index = updatedSelectedAchievements
                .findIndex(({ id }) => id === payload.id);
            updatedSelectedAchievements[index].isTop3 = payload.value;

            return {
                ...state,
                selectedAchievements: updatedSelectedAchievements,
            };
        }
        case CLEAN_SELECTED_ACHIEVEMENTS: {
            return {
                ...state,
                selectedAchievements: [],
            };
        }
        case SET_SOFT_SKILLS_TOP3:
            return {
                ...state,
                softSkillsTop3: payload.softSkillsTop3,
            };
        case SET_SITUATION_CHOICES:
            return {
                ...state,
                situationChoices: payload.situations,
            };
        case SET_DETAILED_SITUATIONS:
            return {
                ...state,
                detailedSituations: payload.detailedSituations,
            };
        case CLEAN_FORM:
            return null;
        default:
            return state;
    }
};

export default reducer;
