import dayjs from 'dayjs';
// in order to add more date formatting, import locales here :
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useContext } from 'react';
import LanguageContext from 'Language/LanguageContext';

const formatDate = (date) => {
    const { language } = useContext(LanguageContext);
    dayjs.extend(LocalizedFormat);

    return dayjs(date).locale(language).format('L');
};

export default formatDate;
