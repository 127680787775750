import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getSituationChoices, selectSituation } from 'actions';
import Button from 'components/common/Button';
import Container from 'components/layout/Container';
import MainWithSteps from 'components/layout/MainWithSteps';
import Panel from 'components/layout/Panel';
import Step3Selection from 'components/Step3/Part1/Selection';
import DescriptionExercise from 'components/StepDescription/DescriptionExercise';
import Instructions from 'components/StepDescription/Instructions';
import StepList from 'components/StepDescription/StepList';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import LanguageContext from 'Language/LanguageContext';
import WithCurrentForm from 'providers/WithCurrentForm';
import { ROUTES } from 'utils/constants';
import './style.scss';
import { skipStep3 } from 'utils/step3';

const Part1 = () => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const push = useLocalizedHistoryPush();
    const { situationChoices } = useSelector(({ currentForm }) => currentForm);
    const [softSkillIndex, setSoftSkillIndex] = useState(0);
    const dispatch = useDispatch();
    const { thirdStepComplete } = useSelector((state) => state.currentForm);
    const [selectedSituationId, setSelectedSituationId] = useState();
    const { language } = useContext(LanguageContext);
    const [skipping, setSkipping] = useState(false);
    const [isInstructionPanelVisible, setInstructionPanelVisibility] = useState(false);

    useEffect(async () => {
        dispatch(getSituationChoices(API_URL));
    }, []);

    useEffect(() => {
        if (situationChoices !== null) {
            const index = situationChoices.findIndex(
                ({ situations }) => situations.every(({ isSelected }) => !isSelected),
            );
            if (index === -1) {
                push(ROUTES.step3.part2);
            } else {
                setSoftSkillIndex(index);
            }

            if (index === 0) {
                setInstructionPanelVisibility(true);
            }
        }
    }, [situationChoices]);

    if (thirdStepComplete) {
        // move on to the next step
        return <Redirect to={ROUTES.form} />;
    }

    if (situationChoices === null) {
        return null;
    }

    if (situationChoices.length === 0) {
        return <Redirect to={ROUTES.account} />;
    }

    const onValidateSituation = async () => {
        await dispatch(selectSituation(API_URL, selectedSituationId));
        if (softSkillIndex === situationChoices.length - 1) {
            push(ROUTES.step3.part2);
        }
        setSoftSkillIndex(softSkillIndex + 1);
        setSelectedSituationId(undefined);
    };

    const onSkip = async () => {
        setSkipping(true);
        skipStep3({ dispatch, push, API_URL, language });
    };

    return (
        <Container>
            <Panel>
                <StepList step={3} />
            </Panel>
            <MainWithSteps
                currentStep={3}
                footer={(
                    <div className="step3__part1__footer">
                        <Button
                            classes="button--block-mobile button--grey"
                            onClick={() => onSkip()}
                            submitting={skipping}
                            text={t('instruction.skip')}
                        />
                        <Button
                            classes="button--block-mobile button--black"
                            disabled={!selectedSituationId}
                            onClick={onValidateSituation}
                            text={t('common.validate')}
                        />
                    </div>
                )}
                subtitle={t(
                    'step3.part1.title.text3',
                    { numberOfSituations: situationChoices[softSkillIndex].situations.length },
                )}
                title={t(
                    'step3.part1.title.text2',
                    { softSkill: t(`softSkill.${situationChoices[softSkillIndex].softSkill}`) },
                )}
            >
                <Step3Selection
                    onSelectSituation={setSelectedSituationId}
                    selectedSituationId={selectedSituationId}
                    situations={situationChoices[softSkillIndex].situations}
                />
            </MainWithSteps>
            {isInstructionPanelVisible && (
                <Instructions
                    onClose={() => setInstructionPanelVisibility(false)}
                    onSkip={() => onSkip()}
                >
                    <DescriptionExercise step={3} />
                </Instructions>
            )}
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm><Part1 {...props} /></WithCurrentForm>
);
