import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkToken, currentUser } from 'actions';
import AuthFormsModal from 'components/AuthFormsModal';
import HomeContent from 'components/home/HomeContent';
import HomeFooter from 'components/home/HomeFooter';
import HomeHeader from 'components/home/HomeHeader';
import { getUrlParams, useLocalizedHistoryPush, useUrlParams } from 'hooks/useUrlParams';
import { AUTH_CONSTANTS } from 'utils/constants';
import './style.scss';

const Home = () => {
    const dispatch = useDispatch();
    const updateUrlParams = useUrlParams();
    const push = useLocalizedHistoryPush();
    const { open: openForm } = getUrlParams();
    const { id } = useSelector(({ auth }) => auth);

    useEffect(async () => {
        const result = await dispatch(checkToken());
        if (result && result.success) {
            await dispatch(currentUser());
        }
    }, []);

    const openSignupForm = () => {
        if (id) {
            push('/login-redirect');
        } else {
            document.body.setAttribute('class', 'body__noScroll');
            updateUrlParams({ open: AUTH_CONSTANTS.SIGNUP });
        }
    };

    const openLoginForm = () => {
        if (id) {
            push('/login-redirect');
        } else {
            document.body.setAttribute('class', 'body__noScroll');
            updateUrlParams({ open: AUTH_CONSTANTS.LOGIN });
        }
    };

    return (
        <div className="home">
            {openForm && (<AuthFormsModal />)}
            <HomeHeader openLogin={openLoginForm} openSignup={openSignupForm} />
            <HomeContent onClick={openSignupForm} />
            <HomeFooter />
        </div>
    );
};

export default Home;
