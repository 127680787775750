import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Backdrop from 'components/common/Backdrop';
import Button from 'components/common/Button';
import HeaderMenu from 'components/HeaderMenu';
import Menu from 'components/layout/Menu';
import SecondaryMenu from 'components/layout/SecondaryMenu';
import { useMenu } from 'hooks/useMenu';
import useWindowSize from 'hooks/useWindowSize';

import './style.scss';

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const Panel = ({ children }) => {
    const { menuIsOpen, openMenu } = useMenu();
    const isMobile = 'xs' === useWindowSize().size;

    const { user } = useSelector((state) => state);

    return (
        <div className="container__panel">
            <ConditionalWrapper
                condition={menuIsOpen}
                // TODO PCD: Refacto to delete eslint-disable
                // eslint-disable-next-line react/no-unstable-nested-components
                wrapper={(child) => (
                    <Backdrop
                        className="panel__backdrop"
                        onClick={(event) => {
                            if (event.target === event.currentTarget) {
                                // only dispatch if the backdrop is clicked
                                // prevent dispatch from being emit due to event bubbling eg. on language switcher click
                                openMenu(!menuIsOpen)
                            }
                        }}
                        zIndex={499}
                    >
                        {child}
                    </Backdrop>
                )}
            >
                <div className={`panel ${menuIsOpen ? 'panel--open' : ''}`}>
                    <header className="panel__header">
                        <HeaderMenu
                            leftElement={(
                                <Link to="/account">
                                    <img alt="Logo" height="30" src="/assets/logo-white.svg" />
                                </Link>
                            )}
                            rightElement={user ? (
                                <Button
                                    classes="header-menu__login-button"
                                    img={menuIsOpen
                                        ? '/assets/icons/close.svg'
                                        : '/assets/icons/burger.svg'}
                                    onClick={() => openMenu(!menuIsOpen)}
                                />
                            ) : null}
                        />
                    </header>
                    {(!isMobile && !menuIsOpen)
                        && (
                            <div className="panel__inner">
                                {children}
                            </div>
                        )}
                    {menuIsOpen && <Menu />}
                    {(!isMobile || menuIsOpen) && <SecondaryMenu />}
                </div>
            </ConditionalWrapper>
        </div>
    );
};
Panel.propTypes = {
    children: PropTypes.node,
};
export default Panel;
