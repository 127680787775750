import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Backdrop from 'components/common/Backdrop';
import Button from 'components/common/Button';
import './style.scss';

const StepInformationModal = ({ onClose, step }) => {
    const { t } = useTranslation();

    return (
        <Backdrop>
            <div className="step-information-modal">
                <div className="step-information-modal__inner">
                    <button onClick={onClose} type="button">
                        <img
                            alt={t('common.close')}
                            className="step-information-modal__close"
                            src="/assets/icons/close_grey.svg"
                        />
                    </button>
                    <h1>
                        {t('informations')}
                    </h1>
                    <p>
                        {t('account.text1')}
                        <br />
                        {t('account.text2')}
                    </p>
                    {/* eslint-disable-next-line react/no-danger */}
                    <h2 dangerouslySetInnerHTML={{ __html: t(`step${step}.title`) }} />
                    <p>
                        {t(`step${step}.description`)}
                    </p>
                    <img
                        alt=""
                        aria-hidden="true"
                        className="step-information-modal__image"
                        src="/assets/vision-future.svg"
                    />
                    <Button classes="button--green" onClick={onClose} text={t('menu.continue')} />
                </div>
            </div>
        </Backdrop>
    );
};

StepInformationModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
};

export default StepInformationModal;
