import classnames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

// Wrap the content of Main to ensure a nice centered display in desktop mode
const MainInner = ({ className = '', children, isLarge }) => (
    <div className={classnames('main-inner', className, { 'main-inner--large': isLarge })}>{children}</div>
);

MainInner.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    isLarge: PropTypes.bool,
};

export default MainInner;
