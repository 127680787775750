import classnames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

const Container = ({ bgColor = 'white', children, className = '', withCard = false }) => (
    <div
        className={classnames(
            'container',
            `container--${bgColor}`,
            {
                'container--card': withCard,
                [className]: className,
            },
        )}
    >
        {children}
    </div>
);

Container.propTypes = {
    bgColor: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    withCard: PropTypes.bool,
};

export default Container;
