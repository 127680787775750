import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.scss';

const DescriptionExercise = ({ step, type = 'long', hiddenDescription = false }) => {
    const { t } = useTranslation();

    const text = t(`step${step}.${type}Description.text`);

    return (
        <div className={`descriptionExercise descriptionExercise__${type}`}>
            <h3>{t(`step${step}.${type}Description.title`)}</h3>
            {!hiddenDescription && (
                <p
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            )}
        </div>
    );
};

DescriptionExercise.propTypes = {
    hiddenDescription: PropTypes.bool,
    step: PropTypes.oneOf([1, 2, 3]).isRequired,
    type: PropTypes.oneOf(['long', 'short']),
};

export default DescriptionExercise;
