import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import LanguageContext from 'Language/LanguageContext';

import './style.scss';

const PageHesterModel = () => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);

    return (
        <div className="page-hester-model">
            <h2 className="pdf__title">{t('pdf.hesterModel.title')}</h2>
            <div className="pdf__text--justify">
                <p className="pdf__p"><Trans i18nKey="pdf.hesterModel.explanation.part1" /></p>
                <p className="pdf__p"><Trans i18nKey="pdf.hesterModel.explanation.part2" /></p>
            </div>
            <div className="page-hester-model__diagram">
                <img
                    alt="Hester model"
                    aria-hidden="true"
                    src={`/assets/pdf/h10model.${language}.svg`}
                />
            </div>
            <div className="pdf__text--justify">
                <p className="pdf__p"><Trans i18nKey="pdf.hesterModel.explanation.part3" /></p>
            </div>
        </div>
    );
};

export default PageHesterModel;
