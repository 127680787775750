import { login, passwordLost, signup } from 'actions';
import LoginForm from 'components/AuthFormsModal/LoginForm';
import LostPasswordForm from 'components/AuthFormsModal/LostPasswordForm';
import SignupForm from 'components/AuthFormsModal/SignupForm';
import Modal from 'components/common/Modal';
import { getUrlParams, useLocalizedHistoryPush, useUrlParams } from 'hooks/useUrlParams';
import { AUTH_CONSTANTS } from 'utils/constants';
import './style.scss';

const onLogin = (values, dispatch) => dispatch(login(values));
const onSignIn = (values, dispatch) => dispatch(signup(values));

const AuthFormsModal = () => {
    const { open: openForm } = getUrlParams();
    const push = useLocalizedHistoryPush();
    const updateUrlParams = useUrlParams();

    const onPasswordLost = (values, dispatchArg) => dispatchArg(passwordLost({
        ...values,
        appName: 'HESTER',
    }));

    return (
        <Modal closeModal={() => updateUrlParams({ open: null })} fullscreen>
            <div className="auth-form">
                {AUTH_CONSTANTS.LOGIN === openForm && (
                    <LoginForm
                        onSubmit={onLogin}
                        onSubmitSuccess={() => push('/login-redirect')}
                    />
                )}
                {AUTH_CONSTANTS.SIGNUP === openForm && (
                    <SignupForm
                        onSubmit={onSignIn}
                        onSubmitSuccess={() => push('/login-redirect')}
                    />
                )}
                {AUTH_CONSTANTS.LOSTPASSWORD === openForm && (
                    <LostPasswordForm
                        onSubmit={onPasswordLost}
                        onSubmitSuccess={() => updateUrlParams({ open: AUTH_CONSTANTS.LOGIN })}
                    />
                )}
            </div>
        </Modal>
    );
};

export default AuthFormsModal;
