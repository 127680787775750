import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.scss';
import '../input.scss';
import fieldPropTypes from 'utils/fieldPropTypes';

const TextAreaField = ({
    className = '',
    input,
    label,
    labelHidden = false,
    maxLength,
    placeholder,
    meta: { touched, error },
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <div
            className={`
                field
                ${touched && error ? 'field--error' : ''}
                ${className}
            `}
        >
            <label className={labelHidden ? 'field__label--hidden' : ''} htmlFor={input.name}>{label}</label>
            <textarea
                className="field__input field__textarea"
                {...input}
                {...props}
                id={input.name}
                maxLength={maxLength}
                placeholder={placeholder}
            />
            {touched && ((error && <span>{t(error)}</span>))}
        </div>
    );
};

TextAreaField.propTypes = {
    ...fieldPropTypes(PropTypes.string.isRequired),
    maxLength: PropTypes.number,
};

export default TextAreaField;
