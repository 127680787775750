import PropTypes from 'prop-types';
import { sortBy } from 'ramda';
import { Trans, useTranslation } from 'react-i18next';

import './style.scss';

const PageAchievements = ({ top3Achievements }) => {
    const { t } = useTranslation();

    const sortedTop3Achievements = sortBy(({ achievement }) => t(`achievement.${achievement.key}`))(top3Achievements);
    const achievements = sortedTop3Achievements.map(({ achievement }) => achievement.key);

    return (
        <>
            <h2 className="pdf__title">{t('pdf.pageAchievements.title')}</h2>
            <div className="pdf__text--justify">
                <p><Trans i18nKey="pdf.pageAchievements.text" /></p>
            </div>
            {achievements.map((achievement, index) => (
                <div key={achievement} className="page-achievements__achievement">
                    <h3 className="pdf__title">
                        {t('pdf.pageAchievements.achievement.title', { index: index + 1 })}
                    </h3>
                    <span>{t(`achievement.${achievement}`)}</span>
                </div>
            ))}
        </>
    );
};

PageAchievements.propTypes = {
    top3Achievements: PropTypes.arrayOf(PropTypes.shape({
        achievement: PropTypes.shape({
            key: PropTypes.string.isRequired,
        }).isRequired,
        isTop3: PropTypes.bool.isRequired,
    })).isRequired,
};

export default PageAchievements;
