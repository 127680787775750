import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'hooks/useWindowSize';
import './style.scss';

const Top3Image = ({ softSkillsTop3 }) => {
    const isMobile = 'xs' === useWindowSize().size;
    const { t } = useTranslation();

    // on mobile, all images are V1. On Desktop, there is on version per softSkill
    const getSoftSkillImagePath = (index, skillKey) => (isMobile ? `V1/${skillKey}.svg` : `V${index}/${skillKey}.svg`);

    const getBackgroundShape2 = () => (
        isMobile
            ? (
                <svg
                    className="top3Image__background-shape2"
                    fill="none"
                    height="286"
                    viewBox="0 0 186 286"
                    width="186"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {/* eslint-disable-next-line max-len */}
                    <path d="M256.52 25.2184L192.919 402.663C190.072 419.56 168.684 425.287 157.775 412.074L6.33988 228.666C-0.475438 220.411 0.416574 208.254 8.36372 201.083L223.4 7.04662C237.473 -5.65222 259.67 6.52629 256.52 25.2184Z" stroke="#FF005F" strokeWidth="2" />
                </svg>
            )
            : (
                <svg
                    className="top3Image__background-shape2"
                    fill="none"
                    height="282"
                    viewBox="0 0 80 282"
                    width="80"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {/* eslint-disable-next-line max-len */}
                    <path d="M469.716 -208.393L320.494 531.084C317.087 547.966 295.356 552.935 284.952 539.211L6.20393 171.545C-0.157226 163.155 1.07609 151.27 9.02446 144.364L436.994 -227.448C451.405 -239.968 473.492 -227.106 469.716 -208.393Z" stroke="#FF005F" strokeWidth="3" />
                </svg>
            )
    );

    return (
        <div className="top3Image__top3">
            <svg
                className="top3Image__background-shape1"
                fill="none"
                height="198"
                viewBox="0 0 198 198"
                width="198"
                xmlns="http://www.w3.org/2000/svg"
            >
                {/* eslint-disable-next-line max-len */}
                <path d="M-223.202 -1.40482L152.669 -73.7288C169.495 -76.9664 182.336 -58.9287 173.769 -44.0896L54.852 161.896C49.5001 171.166 37.798 174.579 28.3017 169.639L-228.652 35.978C-245.469 27.2304 -241.816 2.17686 -223.202 -1.40482Z" stroke="#FF005F" strokeWidth="2" />
            </svg>
            {getBackgroundShape2()}
            {softSkillsTop3.map((skill, i) => (
                <div key={skill}>
                    <img
                        alt=""
                        className={`top3Image__skill-image top3Image__skill-image--${i + 1}`}
                        src={`/assets/softSkills/${getSoftSkillImagePath(i + 1, skill)}`}
                        aria-hidden
                    />
                    <span className={`top3Image__skill-name top3Image__skill-name--${i + 1}`}>
                        {t(`softSkill.${skill}`)}
                    </span>
                </div>
            ))}
        </div>
    );
};

Top3Image.propTypes = {
    softSkillsTop3: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default Top3Image;
