import classnames from 'classnames';
import PropTypes from 'prop-types';
import DescriptionExercise from '../DescriptionExercise';
import './style.scss';

const generateStep = (step, currentStep) => {
    const hiddenDescription = step !== currentStep;

    return (
        <div className={classnames('stepList__step', { 'stepList__step--hidden': hiddenDescription })}>
            <DescriptionExercise hiddenDescription={hiddenDescription} step={step} type="short" />
        </div>
    );
};

const StepList = ({ step }) => (
    <div className="stepList">
        {generateStep(1, step)}
        {generateStep(2, step)}
        {generateStep(3, step)}
    </div>
);

StepList.propTypes = {
    step: PropTypes.number.isRequired,
};

export default StepList;
