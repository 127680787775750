import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { shareOnSocialNetwork } from 'utils/shareOnSocialNetwork';

const ShareButton = ({ buttonContent, disabled = false, shareUrl, socialNetwork }) => (
    <Button
        classes="button--black"
        disabled={disabled}
        onClick={() => {
            shareOnSocialNetwork(socialNetwork, shareUrl);
        }}
        text={buttonContent}
    />
);

ShareButton.propTypes = {
    buttonContent: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    shareUrl: PropTypes.string.isRequired,
    socialNetwork: PropTypes.string.isRequired,
};

export default ShareButton;
