import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import Colored from '../common/Colored';
import PageLayout from '../PageLayout';
import './style.scss';

const SoftSkillSituationText = ({ softSkill, number }) => {
    const { t } = useTranslation();
    const getMetaCapabilitiesText = (skill) => {
        const metaNames = skill.metaCapabilities.map(({ key }) => t(`metaCapability.${key}`));

        return `${metaNames.slice(0, -1).join(', ')} ${t('common.and')} ${metaNames[metaNames.length - 1]}`;
    };

    // step 3 is optional so the detailed situation can be missing. In this case, we do not render anything
    if (!softSkill.detailedSituation?.details) {
        return null;
    }

    return (
        <p>
            <Trans
                i18nKey={`pdf.pageInterviewMemo.softSkill${number}`}
                values={{
                    softSkill: t(`softSkill.${softSkill.key}`),
                    experiencedSituation: t(`situation.${softSkill.detailedSituation.situation.key}`),
                    date: new Date(softSkill.detailedSituation.details.startDate).getFullYear(),
                    context: softSkill.detailedSituation.details.job,
                    task: softSkill.detailedSituation.details.tasks,
                    metaCapabilities: getMetaCapabilitiesText(softSkill),
                }}
            />
        </p>
    );
};

SoftSkillSituationText.propTypes = {
    number: PropTypes.number.isRequired,
    softSkill: PropTypes.shape({
        detailedSituation: PropTypes.shape({
            details: PropTypes.shape({
                job: PropTypes.string.isRequired,
                startDate: PropTypes.string.isRequired,
                tasks: PropTypes.string.isRequired,
            }),
            situation: PropTypes.shape({
                key: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        key: PropTypes.string.isRequired,
        metaCapabilities: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
        }).isRequired),
    }).isRequired,
};

const PageInterviewMemo = ({ createdAt, firstPageIndex, softSkills, user }) => {
    const { t } = useTranslation();

    const sortedSoftSkills = softSkills;
    sortedSoftSkills.sort(({ score: scoreA }, { score: scoreB }) => scoreB - scoreA);

    const softSkillsTop3 = sortedSoftSkills.filter(({ isTop3 }) => isTop3);
    const otherSoftSkills = sortedSoftSkills.filter(({ isTop3 }) => !isTop3);

    return (
        <>
            <PageLayout createdAt={createdAt} page={firstPageIndex} user={user} withoutPadding>
                <Colored color="grey" withPadding>
                    <h2>{t('pdf.pageInterviewMemo.title')}</h2>
                    <p><Trans i18nKey="pdf.pageInterviewMemo.text" /></p>
                </Colored>
                <div className="page-interview-memo__container pdf__text--justify">
                    <p><Trans i18nKey="pdf.pageInterviewMemo.p1" /></p>
                    <p>
                        <Trans
                            i18nKey="pdf.pageInterviewMemo.p2"
                            values={{
                                softSkill1: t(`softSkill.${softSkillsTop3[0].key}`),
                                softSkill2: t(`softSkill.${softSkillsTop3[1].key}`),
                                softSkill3: t(`softSkill.${softSkillsTop3[2].key}`),
                            }}
                        />
                    </p>
                    <SoftSkillSituationText number={1} softSkill={softSkillsTop3[0]} />
                    <SoftSkillSituationText number={2} softSkill={softSkillsTop3[1]} />
                </div>
            </PageLayout>
            <PageLayout createdAt={createdAt} page={firstPageIndex + 1} user={user}>
                <div className="pdf__text--justify">
                    <SoftSkillSituationText number={3} softSkill={softSkillsTop3[2]} />
                    <p>
                        <Trans
                            i18nKey="pdf.pageInterviewMemo.otherSoftSkills"
                            values={{
                                softSkill1: t(`softSkill.${otherSoftSkills[0].key}`),
                                softSkill2: t(`softSkill.${otherSoftSkills[1].key}`),
                            }}
                        />
                    </p>
                </div>
            </PageLayout>
        </>
    );
};

PageInterviewMemo.propTypes = {
    createdAt: PropTypes.string,
    firstPageIndex: PropTypes.number.isRequired,
    softSkills: PropTypes.arrayOf(PropTypes.shape({
        detailedSituation: PropTypes.shape({
            details: PropTypes.shape({
                startDate: PropTypes.string.isRequired,
                tasks: PropTypes.string.isRequired,
            }),
        }),
        key: PropTypes.string.isRequired,
        metaCapabilities: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
        }).isRequired),
        score: PropTypes.number.isRequired,
    })).isRequired,
    user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
    }),
};

export default PageInterviewMemo;
