import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Container from 'components/layout/Container';
import MainWithSteps from 'components/layout/MainWithSteps';
import Panel from 'components/layout/Panel';
import Step2Form from 'components/Step2/Form';
import StepList from 'components/StepDescription/StepList';
import WithCurrentForm from 'providers/WithCurrentForm';
import './style.scss';

const Step2 = () => {
    const { t } = useTranslation();
    const { firstStepComplete, secondStepComplete } = useSelector((state) => state.currentForm);

    if (!firstStepComplete || secondStepComplete) {
        return <Redirect to="/account" />;
    }

    return (
        <Container>
            <Panel>
                <StepList step={2} />
            </Panel>
            <div className="step2-main">
                <MainWithSteps
                    currentStep={2}
                    textContext={t('step2.title.text1')}
                    title={t('step2.title.text2')}
                    isFullWidth
                >
                    <Step2Form />
                </MainWithSteps>
            </div>
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm><Step2 {...props} /></WithCurrentForm>
);
