import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'components/common/Modal';
import ShareButton from 'components/common/ShareButton';
import { FACEBOOK, LINKEDIN } from 'utils/shareOnSocialNetwork';

const ShareResultsModal = ({ shareUrl, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal closeModal={onClose} fullscreen>
            <div className="modal__buttons base-margin">
                <ShareButton
                    buttonContent={t('results.description.action.linkedin')}
                    shareUrl={shareUrl}
                    socialNetwork={LINKEDIN}
                />
                <ShareButton
                    buttonContent={t('results.description.action.facebook')}
                    shareUrl={shareUrl}
                    socialNetwork={FACEBOOK}
                />
            </div>
        </Modal>
    );
};

ShareResultsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    shareUrl: PropTypes.string.isRequired,
};

export default ShareResultsModal;
