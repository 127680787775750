import downloadjs from 'downloadjs';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import AdminTable from 'components/Admin/AdminTable';
import ExportForm from 'components/Admin/ExportForm';
import Button from 'components/common/Button';
import SelectField from 'components/common/form/SelectField';
import Spinner from 'components/common/Spinner';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import MainWithCard from 'components/layout/MainWithCard';
import Panel from 'components/layout/Panel';
import DescriptionAdmin from 'components/StepDescription/DescriptionAdmin';
import { useAppData } from 'hooks/useAppData';
import LanguageContext from 'Language/LanguageContext';
import { USER_ROLES } from 'utils/constants';
import { GET } from 'utils/http';
import { getAllLangs } from 'utils/lang';
import './style.scss';

const Admin = () => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const history = useHistory();
    const [stats, setStats] = useState(null);
    const [statsLanguage, setStatsLanguage] = useState('');
    const { allowedLanguages } = useContext(LanguageContext);
    const isAdmin = useSelector(({ auth }) => auth.status === USER_ROLES.ADMIN);

    if (!isAdmin) {
        return <Redirect to="/account" />;
    }

    useEffect(async () => {
        const response = await GET(`${API_URL}/admin/stats${statsLanguage ? `?language=${statsLanguage}` : ''}`);
        setStats(response);
    }, [statsLanguage]);

    const exportForms = async ({ startDate, endDate, language }) => {
        try {
            const queryParams = `?dateStart=${startDate}&dateEnd=${endDate}${language ? `&language=${language}` : ''}`;
            const response = await GET(
                /* eslint-disable-next-line max-len */
                `${API_URL}/admin/export/forms${queryParams}`,
                null,
                { jsonify: false },
            );
            if (!response) {
                throw new SubmissionError({ _error: 'error.server.unknown' });
            }
            const fileName = `export-hester-forms_${Date.now()}.csv`;
            const file = await response.blob();

            return downloadjs(file, fileName);
        } catch (error) {
            throw new SubmissionError({ _error: error.message });
        }
    };

    if (!stats) {
        return null;
    }

    return (
        <Container bgColor="primary">
            <Panel><DescriptionAdmin /></Panel>
            <Main className="admin" isLarge>
                <MainWithCard
                    header={(
                        <Button
                            classes="button--transparent admin__back-button"
                            img="/assets/icons/white-back.svg"
                            onClick={() => history.goBack()}
                            text={t('common.back')}
                        />
                    )}
                >
                    <h1 className="admin__title">{t('admin.title')}</h1>
                    {stats ? (
                        <>
                            {/* TODO: to be deleted when there are multiple allowed languages */}
                            {allowedLanguages.length > 1 && (
                                <SelectField
                                    input={{
                                        name: 'statLanguage',
                                        onChange: (value) => setStatsLanguage(value),
                                        value: statsLanguage,
                                    }}
                                    label={t('admin.stat.language')}
                                    options={getAllLangs()
                                        .filter(({ isAvailable }) => isAvailable)
                                        .map(({ keyLang, label }) => ({ value: keyLang, label }))}
                                    hasAllOption
                                />
                            )}
                            <ul className="admin__list">
                                <li>{`${t('admin.stat.nbUser')}${stats.nbUser}`}</li>
                                <li>{`${t('admin.stat.nbUserLastSevenDays')}${stats.nbUserLastSevenDays}`}</li>
                                <li>{`${t('admin.stat.nbCompletedForm')}${stats.nbCompletedForm}`}</li>
                                <li>
                                    {`${t('admin.stat.nbCompletedFormLastSevenDays')}
                                    ${stats.nbCompletedFormLastSevenDays}`}
                                </li>
                                <li>{`${t('admin.stat.nbUserWithCompletedForm')}${stats.nbUserWithCompletedForm}`}</li>
                                <li>
                                    {`${t('admin.stat.nbUserWithCompletedFormLastSevenDays')}
                                    ${stats.nbUserWithCompletedFormLastSevenDays}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.averageNumberOfCompletedForm')}
                                    ${stats.averageNumberOfCompletedForm?.toFixed(2) ?? 0}`}
                                </li>
                                <li>
                                    {`${t('admin.stat.averageNumberOfSelectedAchievements')}
                                    ${stats.averageNumberOfSelectedAchievements?.toFixed(2) ?? 0}`}
                                </li>
                            </ul>
                            <ExportForm onSubmit={exportForms} />
                            <AdminTable stats={stats} />
                        </>
                    ) : (
                        <div className="admin__spinner">
                            <Spinner color="black" />
                        </div>
                    )}
                </MainWithCard>
            </Main>
        </Container>
    );
};

export default Admin;
