import downloadjs from 'downloadjs';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Button from 'components/common/Button';
import SelectField from 'components/common/form/SelectField';
import SimpleField from 'components/common/form/SimpleField';
import { useAppData } from 'hooks/useAppData';
import LanguageContext from 'Language/LanguageContext';
import { GET } from 'utils/http';
import { getAllLangs } from 'utils/lang';
import { required } from 'utils/validators';
import './style.scss';

const ExportForm = ({ error, handleSubmit, submitting, valid }) => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const { allowedLanguages } = useContext(LanguageContext);

    const exportTesters = async () => {
        try {
            const response = await GET(`${API_URL}/admin/export/testers`, null, { jsonify: false });
            if (!response) {
                throw new SubmissionError({ _error: 'error.server.unknown' });
            }
            const fileName = `export-hester-testers_${Date.now()}.csv`;
            const file = await response.blob();

            return downloadjs(file, fileName);
        } catch (e) {
            throw new SubmissionError({ _error: e.message });
        }
    };

    return (
        <form className="base-margin export-form" method="post" onSubmit={handleSubmit}>
            <h2 className="title title--medium">{t('admin.export.title')}</h2>
            <div className="export-form__button">
                <Button
                    classes="button--block-mobile button--black"
                    onClick={exportTesters}
                    text={t('admin.button.export.testers')}
                />
            </div>
            {error && <div className="error">{t(`admin.export.error.${error}`)}</div>}
            <div className="export-form__dates">
                <Field
                    component={SimpleField}
                    label={t('admin.export.forms.date.start')}
                    name="startDate"
                    type="date"
                    validate={required}
                />
                <Field
                    component={SimpleField}
                    label={t('admin.export.forms.date.end')}
                    name="endDate"
                    type="date"
                    validate={required}
                />
            </div>
            {/* TODO: to be deleted when there are multiple allowed languages */}
            {allowedLanguages.length > 1 && (
                <div className="export-form__language">
                    <Field
                        component={SelectField}
                        label={t('admin.export.forms.language')}
                        name="language"
                        options={getAllLangs()
                            .filter(({ isAvailable }) => isAvailable)
                            .map(({ keyLang, label }) => ({ value: keyLang, label }))}
                        hasAllOption
                    />
                </div>
            )}
            <div className="export-form__button">
                <Button
                    classes="button--block-mobile button--black"
                    disabled={!valid || submitting}
                    onClick={handleSubmit}
                    text={t('admin.button.export.forms')}
                    type="submit"
                />
            </div>
        </form>
    );
};

ExportForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

export default reduxForm({
    form: 'ExportForm',
})(ExportForm);
