import classnames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

const SelectedAchievementCard = ({ isSelected, onClick, text }) => (
    <button
        className={classnames(
            'step1-part2__card',
            {
                'step1-part2__card--selected': isSelected,
            },
        )}
        onClick={() => onClick(!isSelected)}
        type="button"
    >
        <img alt="checked" src={`/assets/icons/${isSelected ? 'heart-check' : 'heart'}.svg`} />
        <span>
            {text}
        </span>
    </button>
);

SelectedAchievementCard.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
};

export default SelectedAchievementCard;
