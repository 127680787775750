import {
    CLEAR_DETAILED_SITUATION,
    SET_DETAILED_SITUATION,
    SET_RESULT,
} from 'actions/result';

const initialState = {
    detailedSituation: null,
    selectedAchievements: [],
    softSkillsTop3: [],
    canRedoStep3: false,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case SET_DETAILED_SITUATION:
            return {
                ...state,
                detailedSituation: payload.detailedSituation,
            };
        case SET_RESULT:
            return {
                ...state,
                selectedAchievements: payload.selectedAchievements,
                softSkillsTop3: payload.softSkillsTop3,
                canRedoStep3: payload.canRedoStep3,
            };
        case CLEAR_DETAILED_SITUATION:
            return {
                ...state,
                detailedSituation: null,
            };
        default:
            return state;
    }
};

export default reducer;
