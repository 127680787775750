import langData from 'translations/_lang.json';

export const DEFAULT_LANGUAGE = 'fr';

export const DEFAULT_LANGUAGE_MISSING_PAGE = 'en';

export const getAllLangs = () => langData.languages.sort((a, b) => (a.label < b.label ? -1 : 1));

export const getLangInfos = (key) => langData.languages.find((l) => l.keyLang === key);

export const isAValidLanguage = (lang) => langData.languages
    .filter(({ isAvailable }) => isAvailable).find((l) => l.keyLang === lang) !== undefined;

export const extractLangFromIso639 = (isoCode) => isoCode.split('-')[0];

export const getAllLangKeys = () => langData.languages.map((l) => l.keyLang);
