import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tag from 'components/common/Tag';
import './style.scss';

const Top3Achievements = ({ achievements }) => {
    const { t } = useTranslation();

    return (
        <ul className="top3-achievements">
            {
                achievements.map((achievement, index) => (
                    <li key={achievement.key}>
                        <Tag>{`${t('common.achievement')} ${index + 1}`}</Tag>
                        <span className="top3-achievements__label">{t(`achievement.${achievement.key}`)}</span>
                    </li>
                ))
            }
        </ul>
    );
};

Top3Achievements.propTypes = {
    achievements: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
    })).isRequired,
};

export default Top3Achievements;
