import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSoftSkillsTop, markStepComplete } from 'actions';
import Button from 'components/common/Button';
import Container from 'components/layout/Container';
import MainWithSteps from 'components/layout/MainWithSteps';
import Panel from 'components/layout/Panel';
import LoadingScreen from 'components/LoadingScreen';
import Top3Image from 'components/Step2/Top3Image';
import Description from 'components/StepDescription/Description';
import StepList from 'components/StepDescription/StepList';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import LanguageContext from 'Language/LanguageContext';
import WithCurrentForm from 'providers/WithCurrentForm';
import goToStep from 'utils/goToStep';

const FAKE_PROCESSING_TIME = 3000;

const Step2Ending = () => {
    const { API_URL } = useAppData();
    const { language } = useContext(LanguageContext);
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();
    const [loading, setLoading] = useState(true);
    const softSkillsTop3 = useSelector((state) => state.currentForm.softSkillsTop3);
    const { surname } = useSelector(({ user }) => user);
    const { t } = useTranslation();

    useEffect(() => {
        // this is intentional and requested by the client to give the impression to the user of processing time.
        // I strongly suggest to challenge it
        const loadingTimeout = setTimeout(() => { setLoading(false); }, FAKE_PROCESSING_TIME);

        dispatch(getSoftSkillsTop(API_URL));

        return () => {
            clearTimeout(loadingTimeout);
        };
    }, []);

    const goToNextStep = async () => {
        await dispatch(markStepComplete(API_URL, { step: 2 }, language));
        goToStep(push, 3);
    };

    return (
        <Container bgColor="white">
            <Panel>
                {loading
                    ? <StepList step={2} />
                    : (
                        <Description
                            subtitle={t('step2.ending.menu.description')}
                            title={t('step2.ending.menu.title', { surname })}
                        />
                    )}
            </Panel>
            {loading ? <LoadingScreen label={t('common.loading.science')} />
                : (
                    <MainWithSteps
                        footer={(
                            <Button
                                classes="button--block-mobile button--black"
                                onClick={() => goToNextStep()}
                                text={t('common.continue')}
                            />
                        )}
                        subtitle={t('step2.ending.text')}
                        title={t('step2.ending.title')}
                    >
                        <Top3Image softSkillsTop3={softSkillsTop3} />
                    </MainWithSteps>
                )}
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm><Step2Ending {...props} /></WithCurrentForm>
);
