import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createRoti, updateUser } from 'actions';
import EndForm from 'components/EndForm';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import MainWithCard from 'components/layout/MainWithCard';
import Panel from 'components/layout/Panel';
import { useAppData } from 'hooks/useAppData';
import LanguageContext from 'Language/LanguageContext';
import WithCurrentForm from 'providers/WithCurrentForm';
import { ROUTES } from 'utils/constants';
import './style.scss';

const Form = () => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const dispatch = useDispatch();
    const { API_URL } = useAppData();
    const { rotiCompleted } = useSelector(({ user }) => user);

    const submitForm = async (values) => {
        const {
            // user values
            ageRange,
            gender,
            status,
            diploma,
            job,
            reasons,
            // roti values
            satisfaction,
            difficulty,
            aestheticism,
            duration,
            content,
        } = values;
        const userValues = { ageRange, gender, status, diploma, job, reasons };
        const rotiValues = { satisfaction, difficulty, aestheticism, duration, content };
        await dispatch(updateUser(API_URL, userValues));
        await dispatch(createRoti(API_URL, rotiValues, language));
    };

    const goToResults = () => <Redirect to={ROUTES.results} />;

    // if user has already completed the form, we skip this step
    if (rotiCompleted) {
        return <Redirect to={ROUTES.results} />;
    }

    return (
        <Container bgColor="primary" className="form__container">
            <Panel>
                <h2 className="panel-title">{t('sociodemo.description.title')}</h2>
                <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: t('sociodemo.description.content') }}
                    className="panel-content"
                />
            </Panel>
            <Main className="form__main" isLarge>
                <MainWithCard title={t('endForm.title')}>
                    <EndForm
                        onSubmit={submitForm}
                        onSubmitSuccess={goToResults}
                    />
                </MainWithCard>
            </Main>
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm><Form {...props} /></WithCurrentForm>
);
