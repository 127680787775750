import { SubmissionError } from 'redux-form';
import { SET_FORM_STATUS } from './currentForm';
import { GET, DELETE, PATCH } from 'utils/http';

export const CLEAN_USER = 'CLEAN_USER';
export const SET_USER = 'SET_USER';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_USER_HAS_UNCOMPLETED_FORM = 'SET_USER_HAS_UNCOMPLETED_FORM';

export const getUser = (API_URL) => async (dispatch) => {
    try {
        const user = await GET(`${API_URL}/user`);
        dispatch({ type: SET_USER, user });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const checkUserHasUncompletedForm = (API_URL) => async (dispatch) => {
    try {
        const { hasUncompletedForm, formStatus } = await GET(`${API_URL}/user/has-uncompleted-form`);
        dispatch({ type: SET_USER_HAS_UNCOMPLETED_FORM, hasUncompletedForm });
        if (formStatus) {
            dispatch({ type: SET_FORM_STATUS, formStatus });
        }
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const updateUser = (API_URL, values) => async (dispatch) => {
    try {
        const user = await PATCH(`${API_URL}/user/`, { values });
        dispatch({ type: SET_USER, user });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const updateUserLanguage = (API_URL, values) => async (dispatch) => {
    try {
        await PATCH(`${API_URL}/user/language`, values);
        dispatch({ type: SET_USER_LANGUAGE, language: values.language });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const deleteAccount = (API_URL) => async () => {
    try {
        const response = await DELETE(`${API_URL}/user/`);

        if (response.success) {
            return true;
        }

        return null;
    } catch (e) {
        console.error(e);

        return null;
    }
};

export const cleanUser = () => ({
    type: CLEAN_USER,
});
