import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAchievements, getSelectedAchievements, setSelectedAchievement } from 'actions';
import AchievementCard from 'components/Step1/Part1/AchievementCard';
import { useAppData } from 'hooks/useAppData';

const FormAchievements = () => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const dispatch = useDispatch();
    const { achievements } = useSelector((state) => state.currentForm);
    const { selectedAchievements } = useSelector((state) => state.currentForm);

    useEffect(() => {
        // this is to prevent the edge case where a user would click on restart the form while already being on step1,
        // meaning a new form without achievement while staying on the Step1 component being mounted
        if (achievements.length === 0) {
            dispatch(getAllAchievements(API_URL));
            dispatch(getSelectedAchievements(API_URL));
        }
    });

    if (selectedAchievements === null) {
        return null;
    }

    const onAchievementClick = (achievement) => (value) => {
        dispatch(setSelectedAchievement({
            achievement,
            value,
        }));
    };

    return (
        <div className="with-side-padding">
            {achievements.map((achievement) => (
                <div key={achievement.id}>
                    <AchievementCard
                        isSelected={selectedAchievements
                            .findIndex(
                                (selectedAchievement) => selectedAchievement.achievementId === achievement.id,
                            ) >= 0}
                        onClick={onAchievementClick(achievement)}
                        text={t(`achievement.${achievement.key}`)}
                    />
                </div>
            ))}
        </div>
    );
};

export default FormAchievements;
