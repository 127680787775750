import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useAppData } from 'hooks/useAppData';
import './style.scss';

const PageResume = ({ softSkillResults: { softSkillScores } }) => {
    const { t } = useTranslation();
    const { FREYA_URL, MARCO_V3_URL, VASCO_V2_URL } = useAppData();

    const softSkillsTop3 = softSkillScores
        .filter(({ isTop3 }) => isTop3)
        .reduce((values, { softSkill: { key } }) => ({
            ...values,
            [`softSkill${Object.keys(values).length + 1}`]: t(`softSkill.${key}`).toLowerCase(),
        }), {});

    return (
        <div className="page-resume">
            <div className="page-resume__shape">
                <img
                    alt="Page soft skill shape"
                    src="/assets/pdf/page-resume-shape.svg"
                />
            </div>
            <h2 className="pdf__title">{t('pdf.pageResume.title')}</h2>
            <p>
                <Trans
                    i18nKey="pdf.pageResume.text1"
                    values={softSkillsTop3}
                />
            </p>
            <p><Trans i18nKey="pdf.pageResume.text2" /></p>
            <p><Trans i18nKey="pdf.pageResume.text3" /></p>
            <div className="page-resume__next-step">
                <h2 className="pdf__title">{t('pdf.pageResume.nextStep')}</h2>
                <p><Trans i18nKey="pdf.pageResume.nextStep.text1" /></p>
                <p><Trans i18nKey="pdf.pageResume.nextStep.text2" /></p>
                <p><Trans i18nKey="pdf.pageResume.nextStep.text3" /></p>
                <ul>
                    <li
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: t('pdf.pageResume.nextStep.text3.marco', { MARCO_V3_URL }),
                        }}
                    />
                    <li
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: t('pdf.pageResume.nextStep.text3.vasco', { VASCO_V2_URL }),
                        }}
                    />
                    <li
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: t('pdf.pageResume.nextStep.text3.freya', { FREYA_URL }),
                        }}
                    />
                </ul>
                <p
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: t('pdf.pageResume.nextStep.text4') }}
                />
                <p><Trans i18nKey="pdf.pageResume.nextStep.text5" /></p>
            </div>
        </div>
    );
};

PageResume.propTypes = {
    softSkillResults: PropTypes.shape({
        softSkillScores: PropTypes.arrayOf(PropTypes.shape({
            softSkill: PropTypes.shape({
                id: PropTypes.string.isRequired,
                key: PropTypes.string.isRequired,
            }).isRequired,
        })).isRequired,
    }).isRequired,
};

export default PageResume;
