import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Colored from '../common/Colored';
import MetaCapability from '../common/MetaCapability';
import PageLayout from '../PageLayout';
import formatDate from 'utils/formatDate';

import './style.scss';

const PagesMetaCapabilities = ({ createdAt, isTop3, metaCapabilities, pageIndexGetter, user }) => {
    const initialIndex = isTop3 ? 0 : 1;

    return (
        <>
            <PageLayout createdAt={createdAt} page={pageIndexGetter()} user={user}>
                {metaCapabilities.slice(initialIndex, 2 + initialIndex).map((metaCapability, index) => (
                    <MetaCapability
                        key={metaCapability.key}
                        index={index + initialIndex + 1}
                        metaCapability={metaCapability}
                    />
                ))}
            </PageLayout>
            {isTop3 && metaCapabilities.length >= 3 && (
                <PageLayout createdAt={createdAt} page={pageIndexGetter()} user={user}>
                    {metaCapabilities.slice(2).map((metaCapability, index) => (
                        <Fragment key={metaCapability.key}>
                            <MetaCapability index={index + 3} metaCapability={metaCapability} />
                            <div className="pdf-intro-shape">
                                <img
                                    alt="Intro page shape"
                                    src="/assets/pdf/pdf-intro-shape.svg"
                                />
                            </div>
                        </Fragment>
                    ))}
                </PageLayout>
            )}
        </>
    );
};

PagesMetaCapabilities.propTypes = {
    createdAt: PropTypes.string,
    isTop3: PropTypes.bool.isRequired,
    metaCapabilities: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        score: PropTypes.number.isRequired,
    })).isRequired,
    pageIndexGetter: PropTypes.func.isRequired,
    user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
    }),
};

const PageSoftSkill = ({ createdAt, pageIndexGetter, softSkill, softSkillIndex, user }) => {
    const { t } = useTranslation();

    const formatDetailedSituation = ({ startDate, endDate, job, tasks, actions, results }) => (
        <div className="page-soft-skill__context">
            <h4>
                {`${formatDate(startDate)} ${t('softSkillPage.jobUntil')} ${formatDate(endDate)}, ${job}`}
            </h4>
            <p>{tasks}</p>
            <p>{actions}</p>
            <p>{results}</p>
        </div>
    );

    return (
        <div className="page-soft-skill">
            <PageLayout createdAt={createdAt} page={pageIndexGetter()} user={user} withoutPadding>
                <Colored color="grey" withPadding>
                    <div className="page-soft-skill__header">
                        <h2 className="pdf__title">
                            {softSkillIndex}- {t(`softSkill.${softSkill.key}`)}
                        </h2>
                        <p>
                            {t('pdf.pageSoftSkill.category_subtitle', {
                                category: t(`category.${softSkill.categoryKey}`),
                            })}
                        </p>
                    </div>
                </Colored>
                <div className="page-soft-skill__definition">
                    <h3 className="pdf__title strong">{t('pdf.pageSoftSkill.definition_title')}</h3>
                    <p className="pdf__text--justify">{t(`softSkill.${softSkill.key}.definition.part1`)}</p>
                    <p className="pdf__text--justify">{t(`softSkill.${softSkill.key}.definition.part2`)}</p>
                    <p className="pdf__text--justify">{t(`softSkill.${softSkill.key}.definition.part3`)}</p>
                </div>
            </PageLayout>
            <PageLayout createdAt={createdAt} page={pageIndexGetter()} user={user}>
                <div className="page-soft-skill__shape">
                    <img
                        alt="Page soft skill shape"
                        src="/assets/pdf/page-soft-skill-shape.svg"
                    />
                </div>
                <div>
                    <h3 className="pdf__title strong">{t('pdf.pageSoftSkill.main_situations.title')}</h3>
                    <ul className="page-soft-skill__list">
                        {softSkill.experiencedSituations.map((situation) => (
                            <li key={situation.id}>{t(`situation.${situation.key}`)}</li>
                        ))}
                    </ul>
                </div>
                {softSkill.isTop3 && softSkill.detailedSituation?.details && (
                    <div className="page-soft-skill__detailed-situation">
                        <h3 className="pdf__title strong">{t('pdf.pageSoftSkill.context.title')}</h3>
                        <ul className="page-soft-skill__list">
                            <li>{formatDetailedSituation(softSkill.detailedSituation.details)}</li>
                        </ul>
                    </div>
                )}
                {!softSkill.isTop3 && (
                    <div className="page-soft-skill__first-metacapability">
                        <MetaCapability index={1} metaCapability={softSkill.metaCapabilities[0]} />
                    </div>
                )}
            </PageLayout>
            <PagesMetaCapabilities
                createdAt={createdAt}
                isTop3={softSkill.isTop3}
                metaCapabilities={softSkill.metaCapabilities}
                pageIndexGetter={pageIndexGetter}
                user={user}
            />
        </div>
    );
};

PageSoftSkill.propTypes = {
    createdAt: PropTypes.string,
    pageIndexGetter: PropTypes.func.isRequired,
    softSkill: PropTypes.shape({
        categoryKey: PropTypes.string.isRequired,
        detailedSituation: PropTypes.shape({
            details: PropTypes.shape({
                actions: PropTypes.string.isRequired,
                endDate: PropTypes.string.isRequired,
                environment: PropTypes.string.isRequired,
                job: PropTypes.string.isRequired,
                results: PropTypes.string.isRequired,
                startDate: PropTypes.string.isRequired,
                tasks: PropTypes.string.isRequired,
            }).isRequired,
        }),
        experiencedSituations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
        })).isRequired,
        isTop3: PropTypes.bool.isRequired,
        key: PropTypes.string.isRequired,
        metaCapabilities: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            score: PropTypes.number.isRequired,
        })).isRequired,
    }),
    softSkillIndex: PropTypes.number.isRequired,
    user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
    }),
};

export default PageSoftSkill;
