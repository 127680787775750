import { SubmissionError } from 'redux-form';
import { SET_USER } from './user';
import { POST } from 'utils/http';

// eslint-disable-next-line import/prefer-default-export
export const createRoti = (API_URL, values, language) => async (dispatch) => {
    try {
        const user = await POST(`${API_URL}/roti/${language}`, { values });
        dispatch({ type: SET_USER, user });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};
