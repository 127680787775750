import classnames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

const Main = ({ className = '', children, isLarge, isFullWidth = false }) => (
    <main className="main__wrapper">
        <div className={classnames('main', className, { 'main--large': isLarge, 'main--full-width': isFullWidth })}>
            {children}
        </div>
    </main>
);

Main.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    isFullWidth: PropTypes.bool,
    isLarge: PropTypes.bool,
};

export default Main;
