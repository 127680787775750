import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { generateJobSuggestions } from 'utils/softSkill';

import './style.scss';

const PageJobSuggestions = ({ softSkillResults: { softSkillScores } }) => {
    const { t } = useTranslation();

    const jobSuggestionsByJobZone = generateJobSuggestions(
        softSkillScores
            .filter(({ isTop3 }) => isTop3)
            .map(({ softSkill }) => softSkill.key),
    );

    return (
        <div className="page-job-suggestions">
            <h2 className="pdf__title">{t('pdf.pageJobSuggestions.title')}</h2>
            <div className="pdf__text--justify">
                <p className="pdf__p"><Trans i18nKey="pdf.pageJobSuggestions.text1" /></p>
                <p className="pdf__p"><Trans i18nKey="pdf.pageJobSuggestions.text2" /></p>
            </div>
            {jobSuggestionsByJobZone.map((jobZone) => (
                <div key={jobZone.level} className="pdf__text--justify page-job-suggestions__zone">
                    <p><Trans i18nKey={`pdf.pageJobSuggestions.job_zone_${jobZone.level}.intro`} /></p>
                    <ul>
                        {jobZone.suggestions.map(({ index, softSkill }) => (
                            <li key={`${jobZone.level}-${softSkill}`} className="strong">
                                {t(`job_suggestion.${softSkill}.job_zone_${jobZone.level}.suggestion_${index}`)}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

PageJobSuggestions.propTypes = {
    softSkillResults: PropTypes.shape({
        softSkillScores: PropTypes.arrayOf(PropTypes.shape({
            softSkill: PropTypes.shape({
                id: PropTypes.string.isRequired,
                key: PropTypes.string.isRequired,
            }).isRequired,
        })).isRequired,
    }).isRequired,
};

export default PageJobSuggestions;
