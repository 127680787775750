import PropTypes from 'prop-types';
import './style.scss';

const Tag = ({ children, classes = '' }) => (
    <span className={`tag ${classes}`}>
        {children}
    </span>
);

Tag.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
};

export default Tag;
