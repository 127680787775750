import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { recoverForm } from 'actions/currentForm';
import { useAppData } from 'hooks/useAppData';

const WithCurrentForm = ({ children }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const form = useSelector(({ currentForm }) => currentForm);
    const { API_URL } = useAppData();

    useEffect(async () => {
        if (!form.id) {
            await dispatch(recoverForm(API_URL));
        }

        setLoading(false);
    }, []);

    if (loading) {
        return null;
    }

    return form ? children : <Redirect to="/account" />;
};

WithCurrentForm.propTypes = {
    children: PropTypes.node,
};

export default WithCurrentForm;
